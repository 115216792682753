import CountPieChart from './CountPieChart';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useQueryParams } from '../../../hooks/misc';
import { useMemo } from 'react';
import {
  chartViews,
  getChartLabel,
  hiddenAssessments,
} from '../../../utilities/analytics';

const assessmentQuery = loader('./Assessments.fetch.graphql');

export default function Assessments({
  workspaceIds,
  startDate,
  endDate,
  params,
  view = 1,
  status,
  isInteractive,
  header,
  subheader,
}) {
  const { upsertParams } = useQueryParams();
  const filters = useMemo(() => {
    const f = [];
    if (params.inspection_type) {
      f.push({
        field: 'inspectionType',
        operator: 'eq',
        value: params.inspection_type,
      });
    } else {
      f.push({
        field: 'inspectionType',
        operator: 'not',
        value: hiddenAssessments,
      });
    }
    if (startDate && endDate) {
      f.push({
        field: 'dateCreated',
        operator: 'between',
        value: [startDate, endDate],
      });
    }
    if (params.is_draft) {
      f.push({ field: 'isDraft', operator: 'eq', value: params.is_draft });
    }
    return f;
  }, [endDate, params, startDate]);

  const { loading, data: { inspectionsFilteredCount = [] } = {} } = useQuery(
    assessmentQuery,
    {
      fetchPolicy: 'network-only',
      variables: {
        groupBy: chartViews.ASSESSMENTS[view] || 'inspection_type',
        workspaceIds,
        options: {
          filters,
        },
      },
    },
  );

  const total = inspectionsFilteredCount?.reduce(
    (accumulator, current) => accumulator + current.value,
    0,
  );

  const chartData = useMemo(
    () =>
      inspectionsFilteredCount?.map((inspection) => ({
        ...inspection,
        label: getChartLabel(`${inspection.id}`),
      })),
    [inspectionsFilteredCount],
  );
  return (
    <div>
      <CountPieChart
        header={header}
        isLoading={loading}
        data={chartData}
        total={total}
        status={status}
        subheader={subheader}
        isInteractive={isInteractive}
        onClick={({ data }) => {
          if (!isInteractive) {
            return;
          }
          const target = chartViews.ASSESSMENTS[view];
          const v = parseInt(view) + 1;
          upsertParams({ view: v, [target]: data.id });
        }}
      />
    </div>
  );
}
