import { useState, useMemo } from 'react';
import { keys } from '../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getRoute, paths } from '../../constants/strings';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { Text } from '../typography';
import styles from './TemplateRevisionTable.module.scss';
import { Icon } from '@mui/material';
import { customColors } from '../../utilities';
import SimpleUserCard from '../SimpleUserCard';
import { getLocalTime } from '../../utilities/time';

export default function TemplateRevisionTable({ revisions }) {
  const { t } = useTranslation();
  const { workspace } = useWorkspace();
  const navigate = useNavigate();

  const [expandRevisions, setExpandRevisions] = useState(false);

  const tableHeaders = useMemo(
    () => [
      {
        text: t(keys.common.TITLE),
        key: 'title',
      },
      {
        text: t(keys.templates.REVISION_NUMBER),
        key: 'revisionNumber',
      },
      {
        text: t(keys.common.CREATOR),
        key: 'creator',
      },
      {
        text: t(keys.common.STATUS),
        key: 'isDraft',
      },
      { text: t(keys.common.DATE_CREATED), key: 'dateCreated' },
      {
        text: t(keys.common.DATE_MODIFIED),
        key: 'dateModified',
      },
    ],
    [t],
  );

  const getCellValue = (key, value) => {
    switch (key) {
      case 'creator':
        return <SimpleUserCard user={value[key]} />;
      case 'dateModified':
      case 'dateCreated':
        return (
          <Text noMargin weight="semiBold">
            {getLocalTime(value[key]).format('MMM Do, YYYY, HH:mm')}
          </Text>
        );
      case 'isDraft':
        return (
          <Text noMargin weight="semiBold">
            {value.isArchived ? 'Archived' : value[key] ? 'Draft' : 'Published'}
          </Text>
        );
      default:
        return (
          <Text noMargin weight="semiBold">
            {value[key]}
          </Text>
        );
    }
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.header}
        onClick={() => setExpandRevisions(!expandRevisions)}
      >
        <Text noMargin color="secondary" weight="semiBold" size="lg">
          {t(keys.common.REVISIONS)}
        </Text>
        <Icon
          baseclassname="material-icons-outlined"
          sx={{ color: customColors.SECONDARY }}
        >
          {expandRevisions ? 'expand_less' : 'expand_more'}
        </Icon>
      </div>
      <div className={expandRevisions ? styles.visible : styles.hidden}>
        <div className={styles.tableHead}>
          {tableHeaders.map(({ text }, i) => (
            <Text
              key={`header-${i}`}
              className={styles.tableCell}
              noMargin
              weight="semiBold"
              color="secondary"
            >
              {text}
            </Text>
          ))}
        </div>
        <div className={styles.tableBody}>
          {revisions
            .sort((a, b) => b.revisionNumber - a.revisionNumber)
            .map((rev, i) => (
              <div
                key={`row-${i}`}
                className={styles.tableRow}
                onClick={() =>
                  navigate(getRoute(workspace?.id, paths.templates, rev.id))
                }
              >
                {tableHeaders.map(({ key }, i) => (
                  <div key={`cell-${i}`} className={styles.tableCell}>
                    {getCellValue(key, rev)}
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
