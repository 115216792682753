import { Icon } from '@mui/material';
import React, { useMemo, useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Text } from './typography';
import styles from './UserMultiSelector.module.scss';
import SimpleUserCard from './SimpleUserCard';
import { closeModalComponents } from '../utilities/modal';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys';
import { useWorkspacePermissions } from '../providers/WorkspacePermissionsProvider';

export default function UserMultiSelector({
  selected,
  onUserAdded,
  type,
  onUserRemoved,
  readOnly = false,
  placeholder,
  usersToLeaveOut = [],
}) {
  const { t } = useTranslation();
  const { allWorkspaceUsers, allUsers, companyAdmins } =
    useWorkspacePermissions();
  const [search, setSearch] = useState('');

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => setSearch(''), [modalOpen]);

  const workspaceFilteredUsers = useMemo(() => {
    const workspaceUsers = [...allWorkspaceUsers, ...companyAdmins];
    const userMap = new Map();
    workspaceUsers.forEach((user) => {
      userMap.set(user.id, user);
    });

    const uniqueWorkspaceUsers = Array.from(userMap.values());

    return (
      search?.length
        ? uniqueWorkspaceUsers?.filter(
            (user) =>
              user.firstName.toLowerCase().includes(search.toLowerCase()) ||
              user.lastName.toLowerCase().includes(search.toLowerCase()),
          )
        : allWorkspaceUsers
    )
      .filter((l) => !selected?.some((m) => m.id === l.id))
      .filter((u) => !usersToLeaveOut.some((s) => s.id === u.id));
  }, [allWorkspaceUsers, companyAdmins, search, selected, usersToLeaveOut]);

  const allFilteredUsers = useMemo(() => {
    return (
      search?.length
        ? allUsers?.filter(
            (user) =>
              user.firstName.toLowerCase().includes(search.toLowerCase()) ||
              user.lastName.toLowerCase().includes(search.toLowerCase()),
          )
        : allUsers
    )
      .filter((l) => !selected?.some((m) => m.id === l.id))
      .filter((u) => !usersToLeaveOut.some((s) => s.id === u.id));
  }, [search, allUsers, selected, usersToLeaveOut]);

  const ref = useRef(null);
  useEffect(() => {
    closeModalComponents(ref, setModalOpen);
  }, [ref]);

  return (
    <div>
      {selected?.length ? (
        <div className={styles.selectedUserContainer}>
          {selected?.map((user) => (
            <div className={styles.userCard} key={user.id}>
              <SimpleUserCard user={user} size="sm" />
              {!readOnly && !user.readOnly && (
                <Icon
                  className={styles.closeIcon}
                  fontSize="inherit"
                  baseClassName="material-icons-outlined"
                  onClick={() => {
                    onUserRemoved(user);
                  }}
                >
                  close
                </Icon>
              )}
            </div>
          ))}
          {!modalOpen && (
            <div className={styles.addButton}>
              {!readOnly && (
                <Icon
                  className={styles.addIcon}
                  fontSize="inherit"
                  baseClassName="material-icons-outlined"
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  add
                </Icon>
              )}
            </div>
          )}
        </div>
      ) : !readOnly ? (
        <div
          className={styles.buttonContainer}
          onClick={() => setModalOpen(true)}
        >
          <Icon fontSize="inherit" baseClassName="material-icons-outlined">
            person
          </Icon>
          <Text size="md" noSelect noMargin>
            {t(keys.action.ADD, {
              variable: placeholder || t(keys.common.USERS),
            })}
          </Text>
        </div>
      ) : (
        <Text noMargin>{t(keys.common.NONE)}</Text>
      )}
      {modalOpen && (
        <div ref={ref} className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <Icon
              className={styles.hiddenIcon}
              fontSize="inherit"
              baseClassName="material-icons-outlined"
            >
              close
            </Icon>
            <Text
              size="md"
              weight="semiBold"
              color="secondary"
              noSelect
              noMargin
            >
              {t(keys.common.USERS)}
            </Text>
            <Icon
              className={styles.closeIcon}
              fontSize="inherit"
              baseClassName="material-icons-outlined"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              close
            </Icon>
          </div>
          <hr />
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Control
              className={styles.input}
              type="text"
              placeholder={t(keys.action.SEARCH, {
                variable: t(keys.common.USERS),
              })}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Form>
          <div className={styles.userListContainer}>
            {type === 'all'
              ? allFilteredUsers.map((user) => userCard(user, onUserAdded))
              : workspaceFilteredUsers?.map((user) =>
                  userCard(user, onUserAdded),
                )}
          </div>
        </div>
      )}
    </div>
  );
}
export const userCard = (user, onUserAdded) => (
  <div
    key={user.id}
    className={styles.userContainer}
    onClick={() => {
      onUserAdded(user);
    }}
  >
    <img
      className={styles.avatar}
      src={user.avatarUrl}
      alt={`${user.firstName}s avatar`}
    />
    <Text
      size="md"
      weight="semiBold"
      noSelect
      noMargin
    >{`${user.firstName} ${user.lastName}`}</Text>
  </div>
);
