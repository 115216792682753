import React from 'react';
import { pdf } from '@react-pdf/renderer';
import { TemplateDocument } from './templates';
import { AuditDocument } from './audits/audits';
import { ExecutiveSummaryDocument } from './audits/executive_summary';

export const downloadPDF = async ({
  inspectionTemplate = null,
  audit = null,
  props = {},
  summary = null,
  company = {},
  correctiveActions = [],
  notes = [],
  showDetails,
  fileName,
}) => {
  const getDocument = () => {
    if (!!inspectionTemplate) {
      return <TemplateDocument inspectionTemplate={inspectionTemplate} />;
    } else if (!!audit) {
      return (
        <AuditDocument
          inspection={audit}
          props={props}
          correctiveActions={correctiveActions}
          notes={notes}
          fileName={fileName}
        />
      );
    } else if (!!summary) {
      return (
        <ExecutiveSummaryDocument
          inspection={summary}
          company={company}
          correctiveActions={correctiveActions}
          showDetails={showDetails}
        />
      );
    }
  };
  const document = getDocument();
  try {
    const blob = await pdf(document).toBlob();
    const url = URL.createObjectURL(blob);
    const a = window.document.createElement('a');
    a.href = url;
    a.download = `${fileName || 'Document'}.pdf`;
    a.click();
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Failed to generate PDF:', error);
  }
};
