import { useMemo, useRef } from 'react';
import styles from './OfflineDashboard.module.scss';
import { keys } from '../../../utilities/translator/translation_keys';
import { useModal } from '../../../providers/ModalProvider';
import { assessmentTypes } from '../../../constants/strings';
import { modals } from '../../../providers/modals';
import { useCurrentUser } from '../../../providers/UserProvider';
import { useWorkspace } from '../../../providers/WorkspaceProvider';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import QuickAction from '../QuickAction';
import CustomQuickAction from '../CustomQuickAction';
import { Text } from '../../../components/typography';
import { Icon } from '@mui/material';
import { getColor } from '../../../constants/strings';
import {
  tableStyle,
  useTableComponents,
} from '../../../components/tables/MTableComponents.jsx';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useIsMobile } from '../../../hooks/misc.js';
import InspectionCard from '../../../components/inspections/inspection_items/InspectionCard.jsx';
import { useNavigate } from 'react-router-dom';
import { paths, getRoute } from '../../../constants/strings';
import { customColors } from '../../../utilities/misc.js';

export default function OfflineDashboard({ quickActions, inspections }) {
  const { openModal } = useModal();
  const { user } = useCurrentUser();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const {
    workspaceId,
    observationTemplate,
    availableWorkspaces,
    workspaceVehicles,
  } = useWorkspace();
  const { t } = useTranslation();

  const workspaces = availableWorkspaces?.map((w) => ({
    label: `${w.title}`,
    value: `${w.id}`,
  }));
  workspaces.push({ label: `${t(keys.common.NONE)}`, value: 'null' });
  const tableRef = useRef(null);
  const {
    labelCell,
    userFilterOptions,
    dateCell,
    assetCell,
    assetFilterOptions,
    workspaceCell,
    textCell,
  } = useTableComponents();

  const columns = useMemo(
    () => [
      {
        accessorKey: 'inspectionType',
        header: '',
        size: 50,
        filterVariant: 'select',
        filterSelectOptions: [
          ...Object.keys(assessmentTypes)
            .filter((f) => f !== 'observation' && f !== 'incident')
            .map((type) => ({
              value: assessmentTypes[type].key,
              label: t(assessmentTypes[type].title),
            })),
        ],
        Cell: ({ cell }) => (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{
              fontSize: '1.25rem',
              color: getColor(cell?.getValue()?.toLowerCase()),
            }}
          >
            {assessmentTypes[cell.getValue().toLowerCase()]?.icon ||
              'local_shipping'}
          </Icon>
        ),
      },
      {
        accessorKey: 'creator',
        header: '',
        grow: true,
        size: 50,
        enableSorting: false,
        filterVariant: 'select',
        filterSelectOptions: userFilterOptions('workspace'),
        Cell: ({ cell }) => (
          <img
            src={user?.avatarUrl}
            alt="avatar"
            className={styles.avatarUrl}
          />
        ),
      },
      {
        accessorKey: 'isDraft',
        header: t(keys.common.STATUS),
        Header: ({ column }) => (
          <div style={{ textWrap: 'nowrap' }}>{column.columnDef.header}</div>
        ),
        size: 100,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: [
          { value: 'true', label: t(keys.common.IN_PROGRESS) },
          { value: 'false', label: t(keys.common.COMPLETE) },
        ],
        Cell: ({ cell }) =>
          labelCell({
            name: !!cell.getValue()
              ? t(keys.common.IN_PROGRESS)
              : t(keys.common.COMPLETE),
            color: !!cell.getValue() ? 'yellow' : 'green',
          }),
      },
      {
        accessorFn: (row) => row?.template?.title,
        accessorKey: 'template.title',
        header: t(keys.common.TITLE),
        grow: true,
        enableSorting: false,
        minSize: 250,
        Cell: ({ cell }) => textCell({ cell }),
      },

      {
        accessorKey: 'vehicle',
        header: t(keys.common.ASSET),
        grow: true,
        enableSorting: false,
        filterVariant: 'select',
        filterSelectOptions: assetFilterOptions(workspaceVehicles),
        Cell: ({ cell }) => assetCell({ cell }),
      },
      {
        accessorKey: 'workspace',
        header: t(keys.common.WORKSPACE),
        enableSorting: false,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: workspaces,
        Cell: ({ cell }) => workspaceCell({ cell }),
      },
      {
        accessorKey: 'dateModified',
        header: t(keys.common.LAST_UPDATED),
        size: 150,
        Cell: ({ cell }) => dateCell({ cell }, { dateOnly: true }),
        filterVariant: 'date-range',
      },
      {
        accessorKey: 'dateCreated',
        header: t(keys.common.DATE_CREATED),
        size: 150,
        Cell: ({ cell }) => dateCell({ cell }, { dateOnly: true }),
        filterVariant: 'date-range',
      },
      {
        accessorKey: 'dateCompleted',
        header: t(keys.common.DATE_COMPLETED),
        size: 150,
        Cell: ({ cell }) => dateCell({ cell }, { dateOnly: true }),
        filterVariant: 'date-range',
      },
    ],
    [
      userFilterOptions,
      t,
      assetFilterOptions,
      workspaceVehicles,
      workspaces,
      user?.avatarUrl,
      labelCell,
      textCell,
      assetCell,
      workspaceCell,
      dateCell,
    ],
  );

  const baseQuickActions = useMemo(() => {
    return [
      {
        title: t(keys.dashboard.ASSESS),
        icon: 'task',
        color: 'blue',
        onClick: () => {
          openModal({
            modalName: modals.createAssessment,
            variables: {
              participants: [user],
              types: Object.values(assessmentTypes).filter(
                (t) => t.key !== 'INCIDENT' && t.key !== 'OBSERVATION',
              ),
            },
          });
        },
      },
      {
        title: user.company?.observationName || 'Observation',
        icon: 'content_paste_search',
        color: 'darkBlue',
        onClick: () => {
          openModal({
            modalName: modals.observation,
            variables: {
              participants: [],
              workspaceId,
              templateId: observationTemplate?.id,
              questions: observationTemplate?.categories[0]?.questions || [],
              dateTime: new moment().format('YYYY-MM-DD HH:mm'),
            },
          });
        },
      },
      {
        title: user.company?.incidentName || t(keys.common.REPORT),
        icon: 'fmd_bad',
        color: 'red',
        onClick: () =>
          openModal({
            modalName: modals.createIncident,
            variables: { participants: [user] },
          }),
      },
    ];
  }, [
    observationTemplate?.categories,
    observationTemplate?.id,
    openModal,
    t,
    user,
    workspaceId,
  ]);

  const table = useMaterialReactTable({
    data: inspections,
    columns,
    ...tableStyle,
    enableColumnFilters: true,
    enablePagination: true,
    enableFilterMatchHighlighting: true,
    initialState: {
      density: 'compact',
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        const inspection = row.original;
        navigate(
          getRoute(
            inspection?.workspaceId ?? workspaceId,
            paths.assessment,
            inspection.id,
          ),
        );
      },
    }),
    renderTopToolbarCustomActions: () => {
      return (
        <div className={styles.header}>
          <Text noMargin color="secondary" weight="semiBold">
            {t(keys.dashboard.RECENT_ASSESSMENTS)}
          </Text>
          <Icon
            baseClassName="material-icons-outlined"
            sx={{ fontSize: '1.2rem' }}
          >
            task
          </Icon>
        </div>
      );
    },
  });

  return (
    <div className={styles.container}>
      <Text size="lg" weight="bold" noMargin>
        Offline Dashboard
      </Text>
      <div className={styles.flex}>
        <Icon
          baseClassName="material-icons-outlined"
          sx={{ color: customColors.YELLOW, fontSize: '1.5rem' }}
        >
          info
        </Icon>
        <Text noMargin color="secondary">
          {t(keys.dashboard.OFFLINE_30_DAYS)}
        </Text>
      </div>
      <br />
      <div className={styles.page}>
        <div className={styles.quickActions}>
          {quickActions?.map((quickAction) => (
            <CustomQuickAction
              key={quickAction.id}
              quickAction={quickAction}
              isEditing={false}
            />
          ))}
          {baseQuickActions.map(
            ({ title, color, icon, onClick, disabled, className }, idx) => (
              <QuickAction
                key={title + idx + icon}
                title={title}
                icon={icon}
                color={color}
                onClick={onClick}
                disabled={disabled}
                className={className}
              />
            ),
          )}
        </div>

        {isMobile ? (
          inspections?.map((inspection) => (
            <InspectionCard
              key={`inspection:${inspection.id}`}
              inspection={inspection}
              onClick={() =>
                navigate(
                  getRoute(
                    inspection?.workspaceId ?? workspaceId,
                    paths.assessment,
                    inspection.id,
                  ),
                )
              }
            />
          ))
        ) : (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MaterialReactTable
              table={table}
              data={inspections}
              tableRef={tableRef}
            />
          </LocalizationProvider>
        )}
      </div>
    </div>
  );
}
