import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './Inspection.module.scss';
import { loader } from 'graphql.macro';
import { Text } from '../../components/typography';
import { useMutation, useQuery } from '@apollo/client';
import { Spinner } from 'react-bootstrap';
import Accordian from '../../components/Accordian';
import InspectionItemText from '../../components/inspections/inspection_items/InspectionItemText';
import { defaultNavbarState, navbarVar } from '../../graphql/cache/navbar';
import Button from '../../components/Button';
import {
  alertVar,
  openAnalysisModal,
  openFileViewer,
  showToast,
} from '../../graphql/cache/modal';
import { toastVariant, toastLength } from '../../constants/misc';
import RightSidebar from './RightSidebar';
import { Icon } from '@mui/material';
import classNames from 'classnames';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { paths } from '../../constants/strings';
import AssessmentAnalysisCard from '../../components/machine_learning/AssessmentAnalysisCard';
import {
  hasObservations,
  noObservations,
} from '../../components/machine_learning/riskAnalysis';
import { getRoute } from '../../constants/strings';
import noImage from '../../assets/icons/image_blank.png';
import { useInspection } from '../../hooks/offline-hooks/createInspectionhook';
import { useOnlineStatus } from '../../hooks/offline-hooks/offline-misc';
import { useCurrentUser } from '../../providers/UserProvider';
import { useTranslation } from 'react-i18next';
import {
  keys,
  getTranslationKey,
} from '../../utilities/translator/translation_keys.js';
import { isValidUUID } from '../../utilities/offline_hooks_setup_apollo/offlineUtils.js';
import { useModal } from '../../providers/ModalProvider.jsx';
import {
  evaluateCondition,
  formatBundleItems,
} from '../../utilities/inspection.js';
import ReviewFooter from './ReviewFooter/ReviewFooter.jsx';
import { useTemplates } from '../../providers/TemplateProvider.jsx';
import InspectionItemAsset from '../../components/inspections/inspection_items/InspectionItemAsset.jsx';
import SimpleUserCard from '../../components/SimpleUserCard.jsx';
import { getLocalTime, monthSelector } from '../../utilities/time.js';
import Label from '../../components/action_items/Label.jsx';
import {
  cpaKeys,
  cpaStatusTypes,
  riskLevels,
  summaryStringLookup,
} from '../../utilities/audits.js';
import QuickFileDropzone from '../../components/image_uploads/QuickFileDropzone.jsx';
import { customColors } from '../../utilities/misc.js';
import TextWithIcon from '../../components/typography/TextWithIcon.jsx';
import Select from 'react-select';
import InspectionSignatures from './InspectionSignatures.jsx';
import SignatureCanvas from '../../components/SignatureCanvas.jsx';
import Notes from '../../components/notes/Notes.jsx';
import ConditionalInspectionItem from '../../components/inspections/inspection_items/ConditionalInspectionItem.jsx';
import { actionsKeys } from '../../components/question_menu/conditionalActions/conditionalAction.utils.js';
import { Form } from 'react-bootstrap';
import { downloadPDF } from '../../utilities/pdf_export/misc.js';
import InspectionIncidentHeader from './InspectionIncidentHeader.jsx';
import ActionItemCard from '../../components/action_items/ActionItemCard.jsx';
import { useActionItemModal } from '../../hooks/misc.js';

const inspectionQuery = loader('./Inspection.inspection.graphql');
const publishMutation = loader('./Inspection.complete.graphql');
const CPAQuery = loader('../../pages/audit/CPATable.fetch.graphql');
const addInspectionAttachmentMutation = loader(
  './Inspection.addAttachment.graphql',
);
const updateInspectionMutation = loader('./Inspection.update.graphql');
const deleteInspectionAttachmentMutation = loader(
  './Inspection.deleteAttachment.graphql',
);
const addActionItemMutation = loader('./RightSidebar.addActionItem.graphql');
const actionItemsQuery = loader('./RightSidebar.fetchActionItems.graphql');
const checkCategoryFailure = (category, itemMap) =>
  category.questions.some((question) => !!itemMap[question.id]?.failed);

export default function Inspection() {
  const { id } = useParams();
  const { workspace } = useWorkspace();
  const { user: currentUser, isAdmin } = useCurrentUser();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { openConfirmationModal } = useModal();
  const [updateInspection] = useMutation(updateInspectionMutation);
  const [deleteAttachment] = useMutation(deleteInspectionAttachmentMutation);
  const [addActionItem] = useMutation(addActionItemMutation);
  const [addAttachment] = useMutation(addInspectionAttachmentMutation);
  const [loadingAttachment, setLoadingAttachment] = useState(false);
  const { completeInspection, updateBundle, removeBundle } = useInspection();
  useEffect(() => {
    navbarVar({ ...defaultNavbarState, showMenu: false });
  }, []);
  const [publishInspection] = useMutation(publishMutation);
  const online = useOnlineStatus();
  const { t } = useTranslation();
  const { templates } = useTemplates();
  const inspectionId = parseInt(id);
  const [isEditingMonth, setIsEditingMonth] = useState(false);
  const [notes, setNotes] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const completeKey = t(keys.common.COMPLETE);
  const [expand, setExpand] = useState(true);
  const { openActionItemModal } = useActionItemModal();

  const calculateRequiredProgress = (category, itemMap) => {
    let completed = 0;
    let total = category.questions.filter((q) => q.required)?.length;
    const isBundle = category.isBundle;

    if (isBundle) {
      let bundleMultiplier = 1;
      category.questions.forEach((question) => {
        if (itemMap[question.id]) {
          const numberOfBundles = itemMap[question.id]?.length || 1;
          bundleMultiplier = Math.max(bundleMultiplier, numberOfBundles);
        }
      });
      total *= bundleMultiplier;
    }

    category.questions.forEach((question) => {
      if (isBundle && !!itemMap[question.id]) {
        itemMap[question.id]?.forEach((b) => {
          if (!!b.value?.length && question.required) {
            completed += 1;
          }
        });
      } else if (
        !!itemMap[question.id] &&
        !!itemMap[question.id]?.value &&
        question.required
      ) {
        completed += 1;
      }
      if (question?.conditionalAction?.actions?.length > 0) {
        const {
          conditionalAction: { actions },
        } = question;
        for (const { actionable, action } of actions) {
          if (
            action === actionsKeys.followUpQuestion &&
            actionable?.required &&
            evaluateCondition(itemMap[question?.id]?.value, question)
          ) {
            total += 1;
            if (!!itemMap[actionable?.id] && actionable?.required) {
              completed += 1;
            }
          }
        }
      }
    });
    return completed === total;
  };

  const calculateCategoryProgress = (category, itemMap) => {
    let completed = 0;
    let total = category.questions.length;
    category.questions.forEach((question) => {
      if (!!itemMap[question.id]) {
        completed += 1;
      }
      if (question?.conditionalAction?.actions?.length > 0) {
        const {
          conditionalAction: { actions },
        } = question;
        for (const { actionable, action } of actions) {
          if (
            action === actionsKeys.followUpQuestion &&
            evaluateCondition(itemMap[question?.id]?.value, question)
          ) {
            total += 1;
            if (!!itemMap[actionable?.id]) {
              completed += 1;
            }
          }
        }
      }
    });
    return completed === total
      ? completeKey
      : `${
          !calculateRequiredProgress(category, itemMap) ? '* ' : ''
        }${completed} / ${total}`;
  };

  const { refetch, data: { inspections: [inspection] = [{}] } = {} } = useQuery(
    inspectionQuery,
    {
      skip: online ? isNaN(id) : !isValidUUID(id) && isNaN(id),
      fetchPolicy: online ? 'cache-and-network' : 'cache-only',
      variables: {
        options: {
          filters: [
            { field: 'id', value: `${id}`, operator: 'eq' },
            { field: 'isArchived', operator: 'eq', value: ['FALSE'] },
          ],
        },
      },
    },
  );
  const { refetch: refetchActionItems, data: { actionItems = [] } = {} } =
    useQuery(actionItemsQuery, {
      skip: !inspection?.id,
      variables: {
        inspectionId: `${inspection.id}`,
      },
    });

  const assessment = useMemo(() => {
    if (inspection?.template?.id) {
      const template = templates.find((t) => t.id === inspection.template.id);
      return { ...(inspection ?? {}), template };
    }
    return null;
  }, [inspection, templates]);

  const isDraft = assessment?.isDraft && !assessment?.readyForReview;

  const { itemMap, bundleMap } = useMemo(() => {
    let itemMap = {};
    let bundleMap = {};
    if (!assessment?.items) {
      return { bundleMap, itemMap };
    }
    return formatBundleItems(assessment.items);
  }, [assessment]);

  const submitDisabled = useMemo(() => {
    return !assessment?.template?.categories?.every((category) => {
      return calculateRequiredProgress(category, itemMap);
    });
  }, [assessment?.template?.categories, itemMap]);

  const isAudit = assessment?.inspectionType === 'AUDIT';

  const {
    refetch: refetchCPA,
    data: { correctiveActions = [], correctiveActionCount = 0 } = {},
  } = useQuery(CPAQuery, {
    skip: !isAudit || !id || !online,
    variables: {
      options: {
        filters: [{ field: 'inspectionId', operator: 'eq', value: [`${id}`] }],
      },
    },
  });

  const submitToast = () => {
    setTimeout(() => {
      showToast({
        title: t(keys.action.COMPLETED, {
          variable:
            assessment.inspectionType === 'VEHICLE'
              ? t(keys.common.INSPECTION)
              : t(keys.common.REPORT),
        }),
        message: t(keys.assessments.SUBMITTED_MESSAGE, {
          variable:
            assessment.inspectionType === 'VEHICLE'
              ? t(keys.common.INSPECTION)
              : t(keys.common.REPORT),
        }),
        variant: toastVariant.info,
        time: toastLength.md,
      });
    }, 1000);
  };
  const isLoading = !inspection?.id;

  const { vehicle, attachments } = inspection || {};

  const handleMonthChange = (newMonth) => {
    const parts = inspection.documentNumber.split('-');
    const dateParts = parts.slice(-3);
    dateParts[0] = newMonth.value;
    const documentNumber = [...parts.slice(0, -3), ...dateParts].join('-');
    updateInspection({
      variables: {
        id: inspection.id,
        documentNumber,
      },
    }).then(() => setIsEditingMonth(false));
  };
  const execKeys = Object.keys(summaryStringLookup);
  const [executiveSummary, setExecutiveSummary] = useState(
    inspection?.executiveSummary ||
      execKeys.reduce((acc, key) => {
        acc[key] = '';
        return acc;
      }, {}),
  );

  const isIncident = inspection.inspectionType === 'INCIDENT';
  const refImageCount = inspection?.template?.images?.length || 0;
  const inspectionFiles =
    attachments?.filter((f) => f.extension !== 'SIGNATURE') || [];

  const showReferenceFiles = useMemo(() => {
    if (!expand) {
      return false;
    }
    return refImageCount + inspectionFiles?.length !== 0;
  }, [expand, inspectionFiles?.length, refImageCount]);

  const showActionItems = useMemo(() => {
    if (!expand) {
      return false;
    }
    return !!correctiveActionCount || !!actionItems.length;
  }, [actionItems.length, correctiveActionCount, expand]);

  const [selectedOption, setSelectedOption] = useState('Open');

  const actionItemDisplay = useMemo(() => {
    const data = isAudit ? correctiveActions : actionItems;
    const closedStatuses = ['DONE', 'COMPLETE', 'DUPLICATE'];
    if (selectedOption === 'Open') {
      return data?.filter((d) => !closedStatuses.includes(d.status));
    }
    return data?.filter((d) => closedStatuses.includes(d.status));
  }, [actionItems, correctiveActions, isAudit, selectedOption]);

  const openClosedMenu = (
    <div className={styles.flex}>
      <Text
        weight={selectedOption === 'Open' ? 'bold' : 'regular'}
        color="accentPrimary"
        noMargin
        hover
        size="sm"
        onClick={() => setSelectedOption('Open')}
      >
        Open
      </Text>
      <Text
        weight={selectedOption === 'Closed' ? 'bold' : 'regular'}
        color="accentPrimary"
        noMargin
        hover
        size="sm"
        onClick={() => setSelectedOption('Closed')}
      >
        Closed
      </Text>
    </div>
  );

  return !assessment?.template?.id ? (
    <></>
  ) : (
    <div className={styles.split}>
      <div
        className={classNames([styles.container, sidebarOpen && styles.hide])}
      >
        {!isLoading ? (
          <div>
            <div className={styles.header}>
              <Text noMargin weight="bold" size="lg">
                {assessment?.template?.internalType
                  ? t(
                      getTranslationKey(
                        assessment?.template?.title,
                        'assessments',
                      ),
                    )
                  : `${assessment?.template?.title}${
                      assessment?.template?.revisionNumber
                        ? ` (Rev ${assessment.template.revisionNumber})`
                        : ''
                    }`}
              </Text>
              <div className={styles.rightTitle}>
                <Text noMargin weight="bold" size="lg" textAlign="right">
                  {vehicle?.unitNumber}
                </Text>
                <div
                  onClick={() => {
                    setSidebarOpen(true);
                  }}
                  className={classNames([styles.hideDesktop])}
                >
                  <Icon style={{}} baseClassName="material-icons-outlined">
                    more_horiz
                  </Icon>
                </div>
              </div>
            </div>
            {!!inspection?.documentNumber && (
              <>
                <div className={styles.flex}>
                  <Text color="secondary" weight="semiBold" noMargin>
                    {`${t(keys.assessments.DOCUMENT_NUMBER)}: ${
                      inspection?.documentNumber
                    }`}
                  </Text>
                  {inspection?.documentNumber?.split('-')?.length >= 4 &&
                  inspection.isDraft &&
                  isAdmin &&
                  online ? (
                    isEditingMonth ? (
                      <Select
                        placeholder={'Select Month'}
                        options={monthSelector}
                        onChange={(value) => handleMonthChange(value)}
                      />
                    ) : (
                      <Icon
                        baseClassName="material-icons-outlined"
                        onClick={() => setIsEditingMonth(true)}
                        sx={{ fontSize: '1rem', opacity: 0.8 }}
                      >
                        edit
                      </Icon>
                    )
                  ) : null}
                </div>
                {isNaN(inspection?.id) && (
                  <Text noMargin color="secondary" size="sm">
                    {t(keys.assessments.DOCUMENT_NUMBER_DISCLAIMER)}
                  </Text>
                )}
              </>
            )}
            <Text color="secondary" weight="semiBold">
              {assessment?.template?.description}
            </Text>
            {!!vehicle ? (
              <div className={styles.vehicleCard}>
                <InspectionItemAsset
                  vehicle={vehicle}
                  size="lg"
                  readOnly={!assessment?.isDraft}
                />
              </div>
            ) : null}
            <div className={styles.marginLeft}>
              <TextWithIcon
                hover
                weight="semiBold"
                color="accentPrimary"
                textAlign="right"
                icon={expand ? 'expand_less' : 'expand_more'}
                size="sm"
                noMargin
                onClick={() => setExpand(!expand)}
              >
                {!expand ? 'SHOW' : 'HIDE'}
              </TextWithIcon>
            </div>
            {isIncident && !!inspection.incident?.id ? (
              <InspectionIncidentHeader incident={inspection?.incident} />
            ) : null}

            <div className={styles.bottomContainer}>
              {!isDraft && !!assessment.riskAnalysis ? (
                <AssessmentAnalysisCard
                  message={
                    assessment?.riskAnalysis
                      ? t(hasObservations.message)
                      : t(noObservations.message)
                  }
                  analysis={assessment?.riskAnalysis?.split('|') || null}
                  disclaimer={
                    assessment?.riskAnalysis
                      ? t(hasObservations.disclaimer)
                      : t(noObservations.disclaimer)
                  }
                />
              ) : null}
              <Accordian
                title={t(keys.common.REFERENCE_FILES)}
                expand={showReferenceFiles}
                size="md"
                accordianSize={true}
                icon="image"
                backgroundColor={customColors.DARK_GRAY}
                textColor="white"
                iconProps={{ color: 'white' }}
                messageVariant="white"
                message={refImageCount + inspectionFiles?.length}
              >
                {online ? (
                  <>
                    {assessment?.template?.images?.length ? (
                      <div className={styles.referenceImages}>
                        {assessment.template.images.map(({ imageUrl }) => (
                          <img
                            key={imageUrl}
                            className={styles.templateImage}
                            alt="reference"
                            src={imageUrl ? imageUrl : noImage}
                            onClick={() => {
                              const file = {
                                fileType: imageUrl.split('.').pop(),
                                url: imageUrl,
                              };
                              openFileViewer(file);
                            }}
                          />
                        ))}
                      </div>
                    ) : null}
                    <div className={styles.drop}>
                      <QuickFileDropzone
                        icon="true"
                        readOnly={
                          !assessment.isDraft ||
                          currentUser?.id !== assessment?.creator?.id ||
                          !isAdmin
                        }
                        loading={loadingAttachment}
                        attachments={inspectionFiles}
                        setLoading={setLoadingAttachment}
                        onDelete={(id) => {
                          deleteAttachment({ variables: { id } }).then(() =>
                            refetch(),
                          );
                        }}
                        onSubmit={({ url, name, extension }) => {
                          addAttachment({
                            variables: {
                              url,
                              name,
                              extension,
                              inspectionId,
                            },
                          }).then(() => refetch());
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div>
                    <br />
                    <Text
                      size="sm"
                      weight="semiBold"
                      color="secondary"
                      textAlign="center"
                    >
                      {t(keys.common.REFERENCE_FILES_OFFLINE)}
                    </Text>
                  </div>
                )}
              </Accordian>
              <Accordian
                size="md"
                accordianSize={true}
                icon="warning"
                title={
                  isAudit
                    ? 'Corrective Preventative Actions'
                    : t(keys.common.ACTION_ITEMS)
                }
                message={isAudit ? correctiveActionCount : actionItems?.length}
                backgroundColor={customColors.RED_LIGHT}
                textColor="white"
                iconProps={{ color: 'white' }}
                messageVariant="white"
                expand={showActionItems}
              >
                <div className={styles.cpaList}>
                  {isAudit ? (
                    <>
                      {!!correctiveActions?.length && (
                        <div className={styles.marginLeft}>
                          {openClosedMenu}
                        </div>
                      )}
                      {actionItemDisplay?.map(
                        (
                          {
                            assignee,
                            dateCreated,
                            question,
                            status,
                            id,
                            riskLevel,
                            externalId,
                          },
                          idx,
                        ) => (
                          <div
                            className={
                              idx % 2 === 0 ? styles.cpa : styles.cpaOdd
                            }
                            onClick={() => navigate(`/${paths.cpa}/${id}`)}
                            key={id}
                          >
                            <div className={styles.cpaLeft}>
                              <TextWithIcon
                                weight="semiBold"
                                textAlign="right"
                                color="accentPrimary"
                                noMargin
                                link
                              >
                                {externalId}
                              </TextWithIcon>
                              <Text noMargin weight="semiBold" size="sm">
                                {question?.title}
                              </Text>
                              <div className={styles.cpaHeader}>
                                <Label
                                  rowSized={false}
                                  name={
                                    riskLevel
                                      ? `${cpaKeys[riskLevel]?.toUpperCase()} `
                                      : null
                                  }
                                  color={
                                    riskLevels[riskLevel]?.color || 'primary'
                                  }
                                />
                                <Label
                                  rowSized={false}
                                  name={cpaStatusTypes[status]?.title}
                                  color={cpaStatusTypes[status]?.color}
                                />
                              </div>
                            </div>
                            <div>
                              <SimpleUserCard
                                largeAvatar
                                user={assignee}
                                smallText={getLocalTime(dateCreated).format(
                                  'ddd MMM DD YYYY HH:mm:ss',
                                )}
                              />
                            </div>
                          </div>
                        ),
                      )}
                    </>
                  ) : (
                    <div>
                      {inspection.isDraft && (
                        <div className={styles.header}>
                          <TextWithIcon
                            hover
                            size="sm"
                            color="secondary"
                            icon="add"
                            iconProps={{
                              color: customColors.BLUE,
                              fontSize: '1.2rem',
                            }}
                            noMargin
                            onClick={() => {
                              addActionItem({
                                variables: {
                                  title: inspection?.template?.title,
                                  description: '',
                                  type: inspection.inspectionType,
                                  inspectionId: inspection.id,
                                  vehicleId: inspection.vehicle?.id,
                                  workspaceId: workspace?.id,
                                },
                              }).then(
                                ({
                                  data: {
                                    addActionItem: { id },
                                  },
                                }) => {
                                  openActionItemModal(
                                    id,
                                    'VEHICLE',
                                    refetchActionItems,
                                  );
                                },
                              );
                            }}
                          >
                            Add Action Item
                          </TextWithIcon>
                          {!!actionItems?.length && openClosedMenu}
                        </div>
                      )}
                      {actionItemDisplay?.map((actionItem) => {
                        return (
                          <ActionItemCard
                            showName={true}
                            key={actionItem.id}
                            onClick={() =>
                              openActionItemModal(
                                actionItem.id,
                                null,
                                refetchActionItems,
                              )
                            }
                            className={styles.actionItemCard}
                            actionItem={actionItem}
                            boxShadow={false}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </Accordian>
              {assessment?.template?.categories?.map((category) => (
                <div key={`category-${category.id}`}>
                  <Accordian
                    expand={expand}
                    isBundle={category?.isBundle}
                    icon={category.isBundle ? 'layers' : null}
                    iconProps={{
                      color: '#0d6efd',
                      outlined: true,
                      position: 'right',
                    }}
                    title={
                      !!assessment.template.internalType
                        ? t(getTranslationKey(category.title, 'assessments'))
                        : category.title
                    }
                    messageVariant={
                      checkCategoryFailure(category, itemMap)
                        ? 'red'
                        : 'primary'
                    }
                    message={calculateCategoryProgress(category, itemMap)}
                  >
                    {category.isBundle
                      ? !!bundleMap[category.id] &&
                        Object.entries(bundleMap[category.id]).map(
                          ([bundleId, answers], idx) => {
                            const singleQuestion =
                              Object.entries(bundleMap[category.id]).length ===
                              1;
                            return (
                              <div
                                key={`bundle-${bundleId}`}
                                className={styles.bundleContainer}
                              >
                                <Text
                                  noMargin
                                  weight="semiBold"
                                  className={styles.bundleNumber}
                                >{`${idx + 1}. `}</Text>
                                <div
                                  className={
                                    idx % 2 !== 0
                                      ? styles.oddBundle
                                      : styles.evenBundle
                                  }
                                >
                                  {Object.values(answers).map((item) => {
                                    const question = category.questions.find(
                                      (question) =>
                                        question.id === item.questionId,
                                    );

                                    return (
                                      <div
                                        key={`bundle-answer-${item.id}`}
                                        className={styles.bundleQuestion}
                                      >
                                        <InspectionItemText
                                          refetchCPA={refetchCPA}
                                          questionId={question.id}
                                          inspectionId={assessment.id}
                                          cpas={correctiveActions || []}
                                          inspectionVehicleId={
                                            item?.vehicle?.id ??
                                            assessment.vehicle?.id
                                          }
                                          vehicleUnitNumber={
                                            assessment.vehicle?.unitNumber
                                          }
                                          inspectionType={
                                            assessment.inspectionType
                                          }
                                          title={question.title}
                                          description={question.description}
                                          item={item}
                                          additionalData={
                                            question.additionalData
                                          }
                                          inspectionQuery={inspectionQuery}
                                          required={question.required}
                                          isDraft={isDraft}
                                          inputType={question.inputType}
                                          inspectionTitle={
                                            assessment.template.title
                                          }
                                          workspaceId={workspace?.id}
                                          internalType={
                                            assessment.template.internalType
                                          }
                                          refetch={refetch}
                                          isBundle={category.isBundle}
                                          inspection={assessment}
                                          question={question}
                                        />
                                        <ConditionalInspectionItem
                                          item={item}
                                          question={question}
                                          assessment={assessment}
                                          workspace={workspace}
                                          correctiveActions={
                                            correctiveActions || []
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                                  {isDraft && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        width: '100%',
                                      }}
                                    >
                                      <Icon
                                        className={
                                          singleQuestion
                                            ? styles.disabled
                                            : styles.deleteIcon
                                        }
                                        sx={{
                                          fontSize: '1.2rem',
                                        }}
                                        onClick={() => {
                                          if (!singleQuestion)
                                            openConfirmationModal({
                                              title: t(
                                                keys.action.DELETE_VARIABLE,
                                                {
                                                  variable: t(
                                                    keys.assessments.BUNDLE,
                                                  ),
                                                },
                                              ),
                                              description: t(
                                                keys.action.DELETE_CONFIRMATION,
                                                {
                                                  variable: t(
                                                    keys.assessments.BUNDLE,
                                                  ),
                                                },
                                              ),
                                              variant: 'danger',
                                              confirmText: t(
                                                keys.action.DELETE,
                                              ),
                                              onSubmit: () => {
                                                removeBundle({
                                                  inspectionId: assessment.id,
                                                  bundleId,
                                                });
                                              },
                                            });
                                        }}
                                      >
                                        delete_outlined
                                      </Icon>
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          },
                        )
                      : category.questions.map((question, idx) => (
                          <div
                            key={`question-${question.id}`}
                            className={idx % 2 !== 0 ? styles.odd : styles.even}
                          >
                            <InspectionItemText
                              refetchCPA={refetchCPA}
                              questionId={question.id}
                              inspectionId={assessment.id}
                              inspectionVehicleId={
                                itemMap[question?.id]?.vehicle?.id ??
                                assessment.vehicle?.id
                              }
                              onEdit={setIsEditing}
                              vehicleUnitNumber={assessment.vehicle?.unitNumber}
                              inspectionType={assessment.inspectionType}
                              title={question.title}
                              description={question.description}
                              item={itemMap[question.id]}
                              additionalData={question.additionalData}
                              inspectionQuery={inspectionQuery}
                              required={question.required}
                              isDraft={isDraft}
                              inputType={question.inputType}
                              inspectionTitle={assessment.template.title}
                              workspaceId={workspace?.id}
                              internalType={assessment.template.internalType}
                              refetch={refetch}
                              inspection={assessment}
                              cpas={correctiveActions || []}
                              question={question}
                            />
                            <ConditionalInspectionItem
                              item={itemMap[question.id]}
                              question={question}
                              assessment={assessment}
                              workspace={workspace}
                              correctiveActions={correctiveActions || []}
                            />
                          </div>
                        ))}
                    {category.isBundle && isDraft && (
                      <div className={styles.bundleButtons}>
                        <Button
                          outlined
                          onClick={() => {
                            const items = [];
                            category.questions.forEach((question) => {
                              items.push(itemMap[question.id]);
                            });
                            updateBundle({
                              inspectionId: assessment.id,
                              categoryId: category.id,
                              questions: category.questions,
                            });
                          }}
                          icon={'layers'}
                          value={t(keys.assessments.ADD_QUESTIONS)}
                        />
                      </div>
                    )}
                  </Accordian>
                </div>
              ))}
              {isAudit ? (
                <Accordian
                  expand={expand}
                  size="md"
                  accordianSize={true}
                  backgroundColor={customColors.DARK_GRAY}
                  textColor="white"
                  iconProps={{ color: 'white' }}
                  title="Executive Summary"
                  icon="work"
                  message={!!executiveSummary.PUBLISHED ? 'Complete' : 'Draft'}
                >
                  {Object.entries(summaryStringLookup).map(([key, value]) => (
                    <div key={key} className={styles.paddingSm}>
                      <Text noMargin weight="bold">
                        {value}
                      </Text>
                      <div className={styles.spacer} />
                      {!executiveSummary?.PUBLISHED ? (
                        <Form.Control
                          value={executiveSummary[key]}
                          as="textarea"
                          onChange={(e) =>
                            setExecutiveSummary({
                              ...executiveSummary,
                              [key]: e.target.value,
                            })
                          }
                          onBlur={() =>
                            updateInspection({
                              variables: { id: inspectionId, executiveSummary },
                            })
                          }
                        />
                      ) : (
                        <Text noMargin>
                          {executiveSummary[key] || t(keys.common.NONE)}
                        </Text>
                      )}
                    </div>
                  ))}
                  <br />
                  {!!!executiveSummary?.PUBLISHED ? (
                    <Button
                      align="right"
                      value="Publish"
                      outlined
                      icon="check_circle"
                      variant="success"
                      onClick={() => {
                        const final = { ...executiveSummary, PUBLISHED: true };
                        openConfirmationModal({
                          title: 'Publish Executive Summary',
                          description:
                            'Are you sure you are ready to publish? This cannot be edited once submitted.',
                          variant: 'warning',
                          onSubmit: () => {
                            updateInspection({
                              variables: {
                                id: inspectionId,
                                executiveSummary: final,
                              },
                            }).then(() => setExecutiveSummary(final));
                          },
                        });
                      }}
                    />
                  ) : (
                    <Button
                      align={'right'}
                      outlined
                      value="Executive Summary PDF"
                      icon="download"
                      onClick={() =>
                        downloadPDF({
                          summary: inspection,
                          company: currentUser.company,
                          correctiveActions,
                          showDetails: false,
                        })
                      }
                    />
                  )}
                </Accordian>
              ) : null}
              <Accordian
                message={notes?.length || 0}
                expand={isAudit ? isDraft || notes?.length > 0 : expand}
                title={t(keys.common.NOTES)}
                icon="text_snippet"
                size="md"
                accordianSize={true}
                backgroundColor={customColors.DARK_GRAY}
                textColor="white"
                iconProps={{ color: 'white' }}
              >
                <Notes
                  parentId={inspectionId}
                  type={inspection.__typename}
                  setNotes={setNotes}
                  readOnly={isAudit && !isDraft}
                />
                <Text noMargin>{inspection.notes}</Text>
              </Accordian>
              {online ? (
                <Accordian
                  message={
                    attachments?.filter((a) => a.extension === 'SIGNATURE')
                      ?.length
                  }
                  expand={
                    isAudit
                      ? isDraft ||
                        attachments?.filter((a) => a.extension === 'SIGNATURE')
                          ?.length > 0
                      : expand
                  }
                  title="Signatures"
                  size="md"
                  icon="draw"
                  accordianSize={true}
                  backgroundColor={customColors.DARK_GRAY}
                  textColor="white"
                  iconProps={{ color: 'white' }}
                >
                  {!isAudit || (isAudit && isDraft) ? (
                    <div className={styles.sigInput}>
                      <SignatureCanvas
                        onSubmit={(url, failed, pass, name) => {
                          addAttachment({
                            variables: {
                              url,
                              inspectionId: inspection.id,
                              name,
                              extension: 'SIGNATURE',
                            },
                          }).then(() => refetch());
                        }}
                      />
                    </div>
                  ) : null}
                  <br />
                  <InspectionSignatures attachments={attachments} />
                </Accordian>
              ) : null}
              <div className={styles.bottomSpace} />
              {!inspection.isDraft && !inspection.reviewers?.length ? null : (
                <div className={styles.fixedFooter}>
                  <ReviewFooter
                    allQuestionsAnswered={!submitDisabled && !isEditing}
                    inspection={assessment || []}
                    currentUser={currentUser}
                    publishInspection={() => {
                      publishInspection({
                        variables: {
                          id: assessment.id,
                        },
                      });
                    }}
                    onSecondSight={(completedCallback) => {
                      openAnalysisModal({
                        inspection: assessment,
                        onSubmit: () => {
                          completedCallback();
                        },
                      });
                    }}
                    onComplete={() => {
                      if (online && assessment?.template?.analysisRequested)
                        openAnalysisModal({
                          inspection: assessment,
                          onSubmit: () => {
                            publishInspection({
                              variables: {
                                id: assessment.id,
                              },
                            }).then(() => {
                              alertVar({ ...alertVar(), show: false });
                              assessment.incident
                                ? navigate(
                                    getRoute(
                                      workspace?.id,
                                      paths.incident,
                                      assessment?.incident.id,
                                    ),
                                  )
                                : assessment.timecard
                                ? navigate(
                                    `/${paths.timecard}/?id=${assessment.timecard.id}`,
                                  )
                                : navigate(
                                    getRoute(workspace?.id, paths.assessments),
                                  );
                              submitToast();
                            });
                          },
                        });
                      else {
                        openConfirmationModal({
                          title: isAudit
                            ? 'Finalize Audit'
                            : t(keys.action.CONFIRM_CHANGES),
                          description: isAudit
                            ? `Once you publish this audit, the changes will be locked in and can't be changed. Are you sure you want to publish?`
                            : t(keys.action.PUBLISH_CONFIRMATION),
                          variant: 'warning',
                          buttonText: t(keys.action.PUBLISH),
                          onSubmit: () => {
                            completeInspection(assessment.id).then(() => {
                              alertVar({ ...alertVar(), show: false });
                              !online
                                ? navigate(
                                    getRoute(workspace.id, paths.dashboard),
                                  )
                                : assessment.incident
                                ? navigate(
                                    getRoute(
                                      workspace?.id,
                                      paths.incident,
                                      assessment.incident.id,
                                    ),
                                  )
                                : assessment.timecard
                                ? navigate(
                                    `/${paths.timecard}/?id=${assessment.timecard.id}`,
                                  )
                                : isAudit
                                ? navigate(`/${paths.audits}`)
                                : navigate(
                                    getRoute(workspace?.id, paths.assessments),
                                  );
                              submitToast();
                            });
                          },
                        });
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <Spinner
            className={styles.spinner}
            animation="border"
            variant="primary"
          />
        )}
      </div>
      <div className={styles.right}>
        <RightSidebar
          onClose={() => setSidebarOpen(false)}
          className={!sidebarOpen && styles.hide}
          inspection={assessment}
          refetch={refetch}
          notes={notes}
          correctiveActions={correctiveActions || []}
          actionItems={actionItems || []}
        />
      </div>
    </div>
  );
}
