import { format, addDays } from 'date-fns';
import { customColors } from './misc';
import moment from 'moment';
import { keys } from './translator/translation_keys';

export const cpaStatusTypes = {
  DENIED: {
    key: 'DENIED',
    title: 'Unacceptable',
    color: 'red',
    colorString: customColors.RED,
  },
  IN_PROGRESS: {
    key: 'IN_PROGRESS',
    title: 'In Progress',
    color: 'yellow',
    colorString: customColors.YELLOW,
  },
  ACCEPTED: {
    key: 'ACCEPTED',
    title: 'Acceptance',
    color: 'blue',
    colorString: customColors.BLUE,
  },
  COMPLETE: {
    key: 'COMPLETE',
    title: 'Complete',
    color: 'green',
    colorString: customColors.GREEN,
  },
  REVIEWED: {
    key: 'REVIEWED',
    title: 'Review',
    color: 'blue',
    colorString: customColors.BLUE,
  },
  INTERIM: {
    key: 'INTERIM',
    title: 'Interim',
    color: 'yellow',
    colorString: customColors.YELLOW,
  },
  FINAL: {
    key: 'FINAL',
    title: 'Final',
    color: 'orange',
    colorString: customColors.ORANGE,
  },
};
export const auditTypes = ['Internal', 'External', 'Vendor'];

export function getDateColor(dateStr) {
  const inputDate = new Date(dateStr + 'T00:00:00Z');
  const today = new Date();
  const utcYear = today.getUTCFullYear();
  const utcMonth = today.getUTCMonth();
  const utcDate = today.getUTCDate();
  const todayUTC = new Date(Date.UTC(utcYear, utcMonth, utcDate));
  if (inputDate < todayUTC) {
    return 'red';
  } else if (inputDate.getTime() === todayUTC.getTime()) {
    return 'yellow';
  } else {
    return 'green';
  }
}
export const riskLevels = {
  LOW: { color: 'green', title: 'LOW', tableText: 'LOW' },
  MEDIUM: { color: 'yellow', title: 'MEDIUM', tableText: 'MED' },
  HIGH: { color: 'red', title: 'HIGH', tableText: 'HIGH' },
  OBSERVATION: { color: 'gray', title: 'OFI', tableText: 'OFI' },
};

export const cpaKeys = {
  OBSERVATION: 'Opportunity for Improvement',
  LOW: 'Low Risk',
  MEDIUM: 'Medium Risk',
  HIGH: 'High Risk',
};

export const correctiveActionTimelines = (type, level) => {
  const timelines = {
    INTERIM: {
      LOW: 28,
      MEDIUM: 14,
      HIGH: 1,
      OBSERVATION: 30,
    },
    FINAL: { LOW: 90, MEDIUM: 60, HIGH: 60, OBSERVATION: 90 },
  };
  switch (type) {
    case 'INTERIM':
      return timelines.INTERIM[level];
    case 'FINAL':
      return timelines.FINAL[level];
    default:
      return null;
  }
};

export const getDueDate = (days) => {
  const today = new Date();
  let daysFromNow = today;
  if (!!days) {
    daysFromNow = addDays(today, days);
  }
  return format(daysFromNow, 'yyyy-MM-dd 23:59:59');
};
export function calculateRiskString(value) {
  switch (true) {
    case value > 0 && value <= 6:
      return 'LOW';
    case value > 6 && value <= 12:
      return 'MEDIUM';
    case value > 12:
      return 'HIGH';
    default:
      return null;
  }
}

export const riskColors = {
  LOW: customColors.GREEN,
  MEDIUM: customColors.YELLOW,
  HIGH: customColors.RED,
  OBSERVATION: customColors.BLUE,
};

export const correctiveActionTypes = {
  interim: 'INTERIM',
  final: 'FINAL',
  review: 'REVIEW',
};

export const pastDue = (dueDate) => {
  const today = new moment();
  const isOverdue = today > moment(dueDate);
  return isOverdue;
};

export const CPAActionTypes = {
  review: 'CPA-REVIEW',
  interim: 'CPA-INTERIM',
  final: 'CPA-FINAL',
  accepter: 'ACCEPTER',
  acceptanceComment: 'ACCEPTANCE_COMMENT',
  closer: 'CLOSER',
  closingComment: 'CLOSING_COMMENT',
  accept: 'CPA-ACCEPT',
  reviewer: 'REVIEWER',
  participant: 'PARTICIPANT',
};

export const cpaStringLookup = {
  [CPAActionTypes.review]: 'Effectiveness Review',
  [CPAActionTypes.interim]: 'Interim Corrective Action',
  [CPAActionTypes.final]: 'Final Preventative Action',
  [CPAActionTypes.accept]: 'Acceptance Results',
};
export const rootCauseMap = {
  HUMAN_ERROR: 'Human Error',
  PROCESS_ISSUES: 'Process Issues',
  EQUIPMENT_FAILURE: 'Equipment Failure',
  MATERIAL_SUPPLY_ISSUES: 'Material or Supply Issues',
  DESIGN_FLAWS: 'Design Flaws',
  COMMUNICATION_BREAKDOWNS: 'Communication Breakdowns',
  MANAGEMENT_DECISIONS: 'Management Decisions',
  ENVIRONMENTAL_CONDITIONS: 'Environmental Conditions',
  CULTURAL_FACTORS: 'Cultural Factors',
  REGULATORY_CHANGES: 'Regulatory Changes',
};
function mapRootCauseToArray(obj) {
  return Object.entries(obj).map(([value, label]) => ({
    value,
    label,
  }));
}
export const rootCauseOptions = mapRootCauseToArray(rootCauseMap);

export const auditStatus = {
  progress: { color: 'yellow', title: keys.common.IN_PROGRESS },
  complete: { color: 'green', title: keys.common.COMPLETE },
  exec: { color: 'blue', title: keys.common.SUMMARY_RQD },
  cpa: { color: 'red', title: keys.common.PENDING_CPA },
  action: { color: 'red', title: keys.common.PENDING_ACTION },
};

export const auditReportTypes = {
  checklist: 'Audit Checklist',
  summary: 'Executive Summary',
  actions: 'Corrective Actions',
  notes: 'Notes',
  signatures: 'Signatures',
};
export const summaryStringLookup = {
  SUMMARY: 'Summary',
  STRENGTHS: 'Strengths',
  IMPROVEMENT: 'Areas for Improvement',
  RECOMMENDATIONS: 'Recommendations',
  ACTIONS: 'Actions',
};

export const getAuditStatus = ({
  inspection,
  correctiveActions = [],
  actionItems = [],
}) => {
  const isAudit = inspection.inspectionType === 'AUDIT';
  let status = auditStatus.complete;
  if (inspection.isDraft) {
    status = auditStatus.progress;
  } else if (
    !isAudit &&
    !!actionItems?.filter(
      (a) => a.status === 'TODO' || a.status === 'IN_PROGRESS',
    )?.length
  ) {
    status = auditStatus.action;
  } else if (isAudit && inspection?.executiveSummary?.PUBLISHED !== true) {
    status = auditStatus.exec;
  } else if (
    isAudit &&
    correctiveActions?.filter(
      (c) => c.status !== 'COMPLETE' && c.status !== 'DENIED',
    )?.length
  ) {
    status = auditStatus.cpa;
  }
  return status;
};
