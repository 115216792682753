import { useState } from 'react';
import { Button } from '../../components';
import Modal from '../../components/modals/Modal';
import styles from './ReportSelector.module.scss';
import { auditReportTypes } from '../audits';
import { Form } from 'react-bootstrap';
import { Text } from '../../components/typography';
import { downloadPDF } from './misc';

export default function ReportSelector({
  inspection,
  correctiveActions,
  notes,
  fileName,
  options = [],
  buttonText = 'Report',
  buttonIcon = 'download',
  variant = 'primary',
  onSubmit = () => {},
  title = 'Select Report Options',
  isAudit = true,
  subtitle,
}) {
  const [showModal, setShowModal] = useState(false);
  const [props, setProps] = useState({});
  const [selected, setSelected] = useState([]);
  const handleCheckboxChange = (reportType) => {
    setProps((prevSelected) => ({
      ...prevSelected,
      [reportType]: !prevSelected[reportType],
    }));
  };
  const handleSelectAll = () => {
    if (!!options?.length) {
      allOptionsSelected
        ? setSelected([])
        : options.forEach((o) => selected.push(o.id));
    }
    const allOptions = Object.keys(auditReportTypes).reduce((acc, key) => {
      acc[key] = allOptionsSelected ? false : true;
      return acc;
    }, {});

    allOptions['comments'] = true;
    setProps(allOptions);
  };
  const hasProp = Object.values(props).some((value) => value === true);
  const allOptionsSelected = !isAudit
    ? options.length === selected.length
    : !!Object.values(props)?.length &&
      Object.values(props).every((value) => value === true);

  return (
    <div>
      <Button
        value={buttonText}
        icon={buttonIcon}
        variant={variant}
        outlined
        className={styles.pdfButton}
        onClick={() => setShowModal(true)}
      />
      <Modal
        open={showModal}
        submitDisabled={!hasProp && isAudit}
        onClose={() => {
          setProps({});
          setShowModal(false);
        }}
        title={title}
        onSubmit={() => {
          !isAudit
            ? onSubmit(selected)
            : downloadPDF({
                correctiveActions: correctiveActions,
                audit: inspection,
                props,
                notes,
                fileName,
              });
          setProps({});
          setSelected([]);
        }}
      >
        <br />
        {!!subtitle && (
          <Text weight="semiBold" noMargin>
            {subtitle}
          </Text>
        )}
        <br />

        {(isAudit || !!options?.length) && (
          <Text
            weight="semiBold"
            color="accentPrimary"
            onClick={() => handleSelectAll()}
            hover
          >
            {allOptionsSelected ? 'Deselect All' : 'Select All'}
          </Text>
        )}
        {!isAudit ? (
          <div>
            {options?.map((o) => (
              <div className={styles.flexRow} key={o.id}>
                <Form.Check
                  checked={!!selected.includes(o.id)}
                  onChange={() =>
                    setSelected((prev) => {
                      if (prev.includes(o.id)) {
                        return prev.filter((itemId) => itemId !== o.id);
                      } else {
                        return [...prev, o.id];
                      }
                    })
                  }
                />
                <Text noMargin>{o.title}</Text>
              </div>
            ))}
          </div>
        ) : (
          Object.entries(auditReportTypes).map(([key, value]) => (
            <div key={key} className={styles.checkContainer}>
              <div className={styles.check}>
                <Form.Check
                  checked={!!props[key]}
                  onChange={() => handleCheckboxChange(key)}
                />
                <Text noMargin>{value}</Text>
              </div>
              {!!props.checklist && key === 'checklist' && (
                <div className={styles.subCheck}>
                  <Form.Check
                    checked={!!props.comments}
                    onChange={() => handleCheckboxChange('comments')}
                  />
                  <Text noMargin>Comments</Text>
                </div>
              )}
            </div>
          ))
        )}
        <br />
        <br />
      </Modal>
    </div>
  );
}
