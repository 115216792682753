import { Text, View } from '@react-pdf/renderer';
import { textStyles } from '../styles';

export const Extension = ({ extensions }) => {
  return (
    <View>
      {extensions?.map(
        (
          { requestedDate, extensionDate, creatorComments, approverComments },
          index,
        ) => (
          <View key={index}>
            {!!requestedDate && (
              <View>
                <Text style={textStyles.questionSm}>Reason for Extension</Text>
                <Text style={textStyles.answer}>{creatorComments}</Text>
              </View>
            )}
            {!!extensionDate && (
              <View>
                <Text style={textStyles.questionSm}>Comments</Text>
                <Text style={textStyles.answer}>{approverComments}</Text>
              </View>
            )}
          </View>
        ),
      )}
    </View>
  );
};
