import React, { useState, useEffect, useRef } from 'react';
import styles from './InspectionItemText.module.scss';
import Button from '../../Button';
import ImageDropzone from '../../image_uploads/ImageDropzone';
import { openFailureModal } from '../../../graphql/cache/modal';
import { Form } from 'react-bootstrap';
import UserSelector from '../../UserSelector';
import SignatureCanvas from '../../SignatureCanvas';
import { Text } from '../../typography';
import TextInputTable from '../../tables/TextInputTable';
import RiskMatrix from '../../RiskMatrix';
import { statusType, getExtension } from '../../../utilities/inspection';
import findFileIcon from '../../../utilities/files';
import { Spinner } from 'react-bootstrap';
import UserMultiSelector from '../../UserMultiSelector';
import { useInspection } from '../../../hooks/offline-hooks/createInspectionhook';
import ImageWrapper from '../../offline-wrapper-functions/image-wrapper';
import { useModal } from '../../../providers/ModalProvider';
import { modals } from '../../../providers/modals';
import { useTranslation } from 'react-i18next';
import {
  keys,
  getTranslationKey,
} from '../../../utilities/translator/translation_keys.js';
import MultiSelect from '../../forms/MultiSelect.jsx';
import {
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
  MobileDateTimePicker,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { dateOptions } from '../../../utilities/inspection';
import { useIsMobile } from '../../../hooks/misc.js';
import SpeechToText from '../../SpeechToText.jsx';
import { InputAdornment } from '@mui/material';
import { CalendarIcon } from '@mui/x-date-pickers';
import { downloadFile } from '../../../utilities/inspection';
import { sanitizeArray } from '../../../utilities/misc.js';
import DropdownCombo from '../../dropdowns/DropdownCombo.jsx';
import { useWorkspace } from '../../../providers/WorkspaceProvider.jsx';
import { dropdownTypes } from '../../dropdowns/dropdown.js';

export default function InspectionItemInput({
  inputType,
  inputValue,
  setInputValue,
  onSubmit,
  title,
  description,
  additionalData,
  inputTypes,
  inspectionId,
  questionId,
  item,
  isRecording,
  setIsRecording,
  isEditing,
  editingComponent,
  isBundle,
  inspectionType,
  refetchCPA,
}) {
  const { openModal, openConfirmationModal } = useModal();
  const { t } = useTranslation();
  const { deleteInspectionItemUsersResults, addInspectionItemUsersResults } =
    useInspection();
  const { addInspectionTestResults } = useInspection();
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(false);
  const dateFormat = dateOptions[inputType];
  const { workspaceVehicles } = useWorkspace();

  const DateTimePickerComponent =
    isMobile && inputType === inputTypes.dateTime
      ? MobileDateTimePicker
      : DateTimePicker;

  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing && inputRef.current && inputValue) {
      inputRef.current.focus();
    }
  }, [isEditing, inputValue]);

  const pickerStyles = isMobile
    ? {
        textField: {
          sx: { minWidth: '100%' },
          InputProps: {
            endAdornment: (
              <InputAdornment
                sx={{
                  color: '#979797',
                }}
                position="end"
              >
                <CalendarIcon />
              </InputAdornment>
            ),
          },
        },
      }
    : {
        textField: {
          sx: {
            minWidth: '50%',
            marginTop: '0.5rem',
            backgroundColor: 'white',
          },
        },
      };

  const [numberPass, setNumberPass] = useState(null);

  useEffect(() => {
    if (inputType === inputTypes.number) {
      const limits = additionalData?.split('|') || [null, null];
      const min = limits[0] !== '' ? parseFloat(limits[0]) : null;
      const max = limits[1] !== '' ? parseFloat(limits[1]) : null;
      const value = parseFloat(inputValue);
      let pass = true;
      if (min !== null && !isNaN(min) && value < min) {
        pass = false;
      }
      if (max !== null && !isNaN(max) && value > max) {
        pass = false;
      }
      setNumberPass(pass);
    }
  }, [additionalData, inputType, inputTypes.number, inputValue]);

  switch (inputType) {
    case inputTypes.asset:
      return (
        <div>
          <DropdownCombo
            type={dropdownTypes.ASSET}
            items={workspaceVehicles}
            selected={item?.vehicle}
            preventStateChange
            onChange={(vehicle) =>
              onSubmit({ value: 'ASSET', vehicleId: vehicle?.id })
            }
            onRemove={() => onSubmit({ value: '', vehicleId: null })}
          />
        </div>
      );

    case inputTypes.multiUser:
      return (
        <div className={styles.multiUser}>
          <UserMultiSelector
            selected={item?.users}
            type="users"
            onUserAdded={async (user) => {
              let inspectionItemId = item?.id;

              if (!item?.id) {
                const { data } = await addInspectionTestResults(
                  inspectionId,
                  questionId,
                  'USERS',
                  item?.failed || false,
                  item?.status || statusType.pass,
                  item?.failNotes,
                  item?.failImage,
                  item?.userId,
                  item?.additional,
                  null,
                  null,
                  inputType,
                );

                inspectionItemId = data.addInspectionItem.id;
              }
              if (!!item?.id && !item?.value && !!item?.users?.length) {
                onSubmit({ value: 'USERS' });
              }

              addInspectionItemUsersResults(
                user.id,
                inspectionId,
                questionId,
                inspectionItemId,
              );
            }}
            onUserRemoved={(user) => {
              if (item?.users?.length === 1) {
                onSubmit({ value: '' });
              }
              deleteInspectionItemUsersResults(
                user.id,
                inspectionId,
                questionId,
                item?.id,
              );
            }}
          />
        </div>
      );
    case inputTypes.multiImage:
      return (
        <div style={{ marginBottom: '10px' }}>
          {loading ? (
            <Spinner
              className={styles.spinner}
              animation="border"
              variant="primary"
            />
          ) : (
            <div className={styles.multiImage}>
              {inputValue &&
                inputValue
                  ?.split('|')
                  .map((image, index) => (
                    <ImageWrapper
                      key={image + index}
                      alt="uploaded"
                      className={styles.image}
                      image={image}
                    />
                  ))}
            </div>
          )}
          <ImageDropzone
            title={title}
            onLoading={() => setLoading(true)}
            onSubmit={(url) => {
              setLoading(true);
              const newValue = inputValue ? `${inputValue}|${url}` : `${url}`;
              setInputValue(newValue);
              onSubmit({ value: newValue });
              setLoading(false);
            }}
          />
        </div>
      );
    case inputTypes.file:
      return loading ? (
        <Spinner
          className={styles.spinner}
          animation="border"
          variant="primary"
        />
      ) : (
        <div className={styles.multiFileDisplay}>
          <Button
            value={t(keys.action.UPLOAD_VARIABLE, {
              variable: t(keys.common.FILE),
            })}
            icon="backup"
            onClick={() => {
              openModal({
                modalName: modals.fileUpload,
                variables: {
                  assessment: true,
                  simple: true,
                  onSubmit: ({ url, name }) => {
                    onSubmit({ value: url, additional: name });
                    setLoading(false);
                  },
                  onLoading: () => {
                    setLoading(true);
                  },
                },
              });
            }}
          />
        </div>
      );
    case inputTypes.multiFile:
      return (
        <div className={styles.multiFileDisplay}>
          {loading ? (
            <Spinner
              className={styles.spinner}
              animation="border"
              variant="primary"
            />
          ) : additionalData ? (
            additionalData?.split('|').map((additional, i) => (
              <div
                className={styles.fileDisplay}
                key={`name-${additional} + ${i}`}
                style={{ marginBottom: '1.5rem' }}
                onClick={() =>
                  openConfirmationModal({
                    title: t(keys.action.DOWNLOAD_VARIABLE, {
                      variable: additional,
                    }),
                    description: t(keys.action.DOWNLOAD_CONFIRMATION, {
                      variable: additional,
                    }),
                    variant: 'warning',
                    onSubmit: () => {
                      const url = item?.value?.split('|')[i];
                      downloadFile(url, additional);
                    },
                  })
                }
              >
                <div className={styles.card}>
                  <img
                    className={styles.fileIcon}
                    alt={`${additional} file`}
                    src={findFileIcon(
                      getExtension(
                        inputValue?.includes('|')
                          ? inputValue?.split('|')[i]
                          : inputValue,
                      ),
                    )}
                  />
                  <div>
                    <Text noMargin weight="semiBold">
                      {additional}
                    </Text>
                  </div>
                </div>
              </div>
            ))
          ) : null}
          <Button
            value={t(keys.action.UPLOAD_VARIABLE, {
              variable: t(keys.common.FILE),
            })}
            disabled={loading}
            icon="backup"
            onClick={() => {
              openModal({
                modalName: modals.fileUpload,
                variables: {
                  onSubmit: ({ url, name }) => {
                    const newValue = inputValue ? `${inputValue}|${url}` : url;
                    setInputValue(newValue);
                    setLoading(false);
                    onSubmit({ value: newValue, additional: name });
                  },
                  onLoading: () => {
                    setLoading(true);
                  },
                  previousAdditional: additionalData,
                  previousValue: inputValue,
                  simple: true,
                  assessment: true,
                },
              });
            }}
          ></Button>
        </div>
      );
    case inputTypes.multiText:
      return (
        <div className={styles.inputContainer}>
          <Form.Control
            ref={item?.id === editingComponent ? inputRef : undefined}
            as="textarea"
            className={styles.textInput}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            onBlur={() => {
              onSubmit({ value: inputValue });
            }}
            placeholder={t(keys.action.ENTER, {
              variable: t(keys.templates.TEXT),
            })}
            value={inputValue || ''}
            rows={5}
          />
        </div>
      );
    case inputTypes.matrix:
      return (
        <RiskMatrix
          impact={item?.impact}
          probability={item?.probability}
          onChange={(impact, probability) => {
            onSubmit({ value: 'MATRIX', impact, probability });
          }}
        />
      );
    case inputTypes.time:
      return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <TimePicker
            onChange={(newValue) => {
              setInputValue(newValue?.format(dateFormat.format));
            }}
            onAccept={() => onSubmit({ value: inputValue })}
            closeOnSelect={false}
            format={dateFormat.format}
            timeSteps={dateFormat.timeSteps}
            value={
              inputValue ? new moment(inputValue, dateFormat.format) : null
            }
            slotProps={pickerStyles}
            label={t(keys.action.SELECT_VARIABLE, {
              variable: dateFormat.title,
            })}
          />
        </LocalizationProvider>
      );

    case inputTypes.date:
    case inputTypes.dateTime:
      return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePickerComponent
            fullWidth
            onChange={(newValue) => {
              setInputValue(newValue?.format(dateFormat.format));
            }}
            onAccept={() => onSubmit({ value: inputValue })}
            closeOnSelect={false}
            value={
              inputValue ? new moment(inputValue, dateFormat.format) : null
            }
            slotProps={pickerStyles}
            format={dateFormat.format}
            inputFormat={dateFormat.format}
            views={dateFormat.views}
            timeSteps={dateFormat.timeSteps}
            label={t(keys.action.SELECT_VARIABLE, {
              variable: dateFormat.title,
            })}
          />
        </LocalizationProvider>
      );

    case inputTypes.multiSignature:
      return (
        <div className={styles.multiSignatureDisplay}>
          {inputValue
            ? inputValue?.split('|')?.map((sig, i) => (
                <div key={sig} className={styles.signature}>
                  <Text
                    key={additionalData?.split('|')[i]}
                    color="accentPrimary"
                    className={styles.textResponse}
                  >
                    {additionalData?.split('|')[i]}
                  </Text>
                  <ImageWrapper
                    alt="signature4"
                    className={styles.displayImage}
                    image={sig}
                  />
                </div>
              ))
            : null}
          <SignatureCanvas
            originalAdditional={additionalData}
            onSubmit={(newSignature, failed, status, additional) => {
              const newValue = inputValue
                ? `${inputValue}|${newSignature}`
                : newSignature;
              setInputValue(newValue);
              onSubmit({ value: newValue, failed, status, additional });
            }}
          />
        </div>
      );

    case inputTypes.signature:
      return (
        <div>
          <SignatureCanvas
            originalAdditional={additionalData}
            onSubmit={(newValue, failed, status, additional) =>
              onSubmit({ value: newValue, failed, status, additional })
            }
          />
        </div>
      );
    case inputTypes.table:
      return (
        <div
          style={{
            margin: 'auto',
          }}
        >
          <TextInputTable
            headers={additionalData?.split('|')}
            setInputValue={setInputValue}
            inputValue={inputValue}
            onChange={(rows) => {
              onSubmit({ value: rows.join('|') });
            }}
            title={title}
          />
        </div>
      );
    case inputTypes.multiSelect:
      const multiOptions = additionalData?.split('|');
      return (
        <MultiSelect
          title={''}
          options={multiOptions}
          values={item?.value?.split('|') || []}
          onChange={(newValue) => {
            const value = sanitizeArray(newValue);
            onSubmit({ value });
          }}
        />
      );
    case inputTypes.select:
      const singleOptions = additionalData?.split('|') ?? [];
      return singleOptions?.map((o, index) => (
        <Form.Check
          inline
          label={o}
          name={`single-select-${item?.id}`}
          type={'radio'}
          id={inputType + title + index + description}
          checked={item?.value === o}
          value={o}
          key={inputType + title + index + description}
          onChange={(e) => {
            onSubmit({ value: e.target.value });
          }}
        />
      ));
    case inputTypes.user:
      return (
        <UserSelector
          className={styles.avatarBox}
          type="User"
          selected={inputValue.user}
          onSelectionChanged={(user) => {
            setInputValue({
              userId: user ? user.id : null,
              user: user,
            });
            if (user?.id) {
              onSubmit({ value: user.firstName, userId: user.id });
            }
            setInputValue('');
          }}
        />
      );
    case inputTypes.test:
      return (
        <TestInput
          inspectionType={inspectionType}
          title={title}
          description={description}
          onSubmit={onSubmit}
          additional={additionalData ? additionalData?.split('|') : null}
          inspectionId={inspectionId}
          questionId={questionId}
          t={t}
          refetchCPA={refetchCPA}
        />
      );
    case inputTypes.image:
      return (
        <div className={styles.textInput}>
          {loading ? (
            <Spinner
              className={styles.spinner}
              animation="border"
              variant="primary"
            />
          ) : (
            <ImageDropzone
              title={title}
              onLoading={() => setLoading(true)}
              onSubmit={(url) => {
                setLoading(true);
                onSubmit({ value: url });
                setLoading(false);
              }}
            />
          )}
        </div>
      );
    case inputTypes.text:
      return (
        <div className={styles.inputContainer}>
          <Form
            onSubmit={(e) => e.preventDefault()}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            className={styles.textInput}
            onBlur={() => {
              onSubmit({ value: inputValue });
            }}
          >
            <input
              ref={item?.id === editingComponent ? inputRef : undefined}
              className={styles.input}
              value={inputValue || ''}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={t(keys.action.ENTER, {
                variable: t(keys.templates.TEXT),
              })}
            />
          </Form>
        </div>
      );
    case inputTypes.audio:
      return loading ? (
        <Spinner variant="primary" animation="border" />
      ) : (
        <SpeechToText
          isRecording={isRecording}
          setIsRecording={setIsRecording}
          transcript={inputValue}
          setTranscript={setInputValue}
          isAssessmentQuestion
          setLoading={setLoading}
          onSubmit={(transcript, URL) => {
            onSubmit({ value: transcript, additional: URL });
          }}
        />
      );
    case inputTypes.number:
      return (
        <div className={styles.inputContainer}>
          <Form.Control
            type="number"
            ref={item?.id === editingComponent ? inputRef : undefined}
            className={styles.textInput}
            onSubmit={(e) => e.preventDefault()}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            isValid={numberPass === true && inputValue}
            isInvalid={numberPass === false && inputValue}
            onBlur={() => {
              onSubmit({
                value: inputValue,
                additional: item?.additional,
                failed: !numberPass,
                status: numberPass ? statusType.pass : statusType.fail,
              });
            }}
            placeholder={t(keys.action.ENTER, {
              variable: t(keys.templates.NUMBER),
            })}
            value={inputValue}
          />
        </div>
      );
    default:
      return (
        <Text size="sm" color="secondaryLight" noMargin>
          {t(keys.assessments.INVALID_QUESTION_TYPE)}
        </Text>
      );
  }
}

const TestInput = ({
  onSubmit,
  title,
  description,
  additional,
  t,
  inspectionType,
  questionId,
  inspectionId,
  refetchCPA,
}) => {
  const { openModal } = useModal();

  return (
    <div className={styles.testButtonContainer}>
      <Button
        variant="success"
        value={
          additional
            ? t(getTranslationKey(additional[0], 'assessments')) ||
              additional[0]
            : `${t(keys.assessments.PASS)}`
        }
        size="sm"
        onClick={() =>
          onSubmit({
            value: statusType.pass,
            status: statusType.pass,
            failed: false,
          })
        }
      />
      <Button
        variant="danger"
        value={
          additional
            ? t(getTranslationKey(additional[1], 'assessments')) ||
              additional[1]
            : `${t(keys.assessments.FAIL)}`
        }
        size="sm"
        onClick={() => {
          inspectionType === 'AUDIT'
            ? openModal({
                modalName: modals.CPA,
                variables: {
                  title,
                  questionId,
                  inspectionId,
                  onSubmit: ({ failNotes, userId }) => {
                    onSubmit({
                      value: statusType.fail,
                      failed: true,
                      status: statusType.fail,
                      failNotes,
                      userId,
                    });
                    refetchCPA();
                  },
                },
              })
            : openFailureModal({
                title,
                description,
                onSubmit: (notes, imageUrl, assigneeId) => {
                  onSubmit({
                    value: statusType.fail,
                    userId: assigneeId,
                    failed: true,
                    status: statusType.fail,
                    failNotes: notes,
                    failImage: imageUrl,
                  });
                },
              });
        }}
      />
      <Button
        className={additional?.length === 2 && styles.hide}
        variant="secondary"
        value={additional ? additional[2] : 'N/A'}
        size="sm"
        onClick={() =>
          onSubmit({
            value: statusType.notApplicable,
            failed: false,
            status: statusType.notApplicable,
          })
        }
      />
    </div>
  );
};
