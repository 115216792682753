import { assessmentTypes } from '../constants/strings';
import i18n from 'i18next';
import { getTranslationKey, keys } from './translator/translation_keys';

export const hiddenAssessments = ['OBSERVATION'];

export const typeOptions = {
  inspection: Object.values(assessmentTypes)
    .filter((f) => !hiddenAssessments.includes(f.key))
    .map((a) => ({
      label: a.title,
      value: a.key,
      key: 'type',
    })),
};
export const observationKPIs = [
  { id: 'Total', title: 'Total Observations', positive: true },
  {
    id: 'Positive',
    title: 'Total Positive',
    positive: true,
    additionalFilters: [
      { field: 'negative', operator: 'eq', value: [`${false}`] },
    ],
  },
  {
    id: 'Negative',
    title: 'Total Negative',
    additionalFilters: [
      { field: 'negative', operator: 'eq', value: [`${true}`] },
    ],
  },
];

export const analyticFilterParams = ['view', 'charttab', 'tab'];

export const cpaKPIs = [
  {
    id: 'ICA',
    title: 'Interim Corrective Action Deadline',
    description:
      'Indicates the percentage of Interim Corrective Actions that go past the due date',
    units: '%',
  },
  {
    id: 'FPA',
    title: 'Final Preventative Action Deadline',
    description:
      'Indicates the total number of Final Preventative that go past the due date',
    units: '%',
  },
  {
    id: 'EXT',
    title: 'Number of Extensions Requested',
    description: 'Indicates the total number of Action Extensions Requested',
  },
  {
    id: 'HIGH',
    title: 'Percentage of High Risk Findings',
    description:
      'Indicates the total number of High Risk findings compared to total findings',
    formula: '(Number of High Risk Findings/Total Number of Findings} * 100',
    units: '%',
  },
  {
    id: 'MEDIUM',
    title: 'Percentage of Medium Risk Findings',
    description:
      'Indicates the total number of Medium Risk findings compared to total findings',
    formula: '(Number of Medium Risk Findings/Total Number of Findings} * 100',
    units: '%',
  },
  {
    id: 'LOW',
    title: 'Percentage of Low Risk Findings',
    description:
      'Indicates the total number of Low Risk findings compared to total findings',
    formula: '(Number of Low Risk Findings/Total Number of Findings} * 100',
    units: '%',
  },
  {
    id: 'OFI',
    title: 'Percentage of Improvement Opportunities',
    description:
      'Indicates the total number of Opportunities for Improvement compared to total findings',
    formula: '(Number of Observations/Total Number of Findings} * 100',
    units: '%',
  },
  {
    id: 'REPEAT',
    title: 'Number of Repeat Findings',
    description: 'Indicates the total number of repeat findings',
  },
];
export const incidentKPIs = [
  {
    id: 'TRIR',
    title: 'Total Recordable Incident Rate',
    description:
      'Indicates the total number of reportable incidents per 200,000 hours worked.',
    formula: '(Number of Recordable Incidents / Total Hours Worked) * 200,000',
    units: '%',
  },
  {
    id: 'LTIR',
    title: 'Lost Time Injury Frequency Rate',
    description:
      'Indicates the number of lost-time injuries per million hours worked.',
    formula: '(Number of Lost Time Injuries / Total Hours Worked) * 1,000,000',
    additionalFilters: [{ field: 'lostTime', operator: 'eq', value: 'true' }],
    units: '%',
  },
  {
    id: 'NMFR',
    title: 'Near Miss Frequency Rate',
    description:
      'Measures the frequency of near-miss incidents before they lead to actual incidents.',
    formula: '(Number of Near Misses / Total Hours Worked) * 200,000',
    additionalFilters: [{ field: 'nearMiss', operator: 'eq', value: 'true' }],
    units: '%',
  },
  {
    id: 'SR',
    title: 'Severity Rate',
    description:
      'Measures the severity of incidents based on lost days per incident.',
    formula: '(Total Lost Days / Total Incidents)',
    units: '%',
  },
  {
    id: 'TTRI',
    title: 'Time to Resolve Incident',
    description:
      'Average time taken to resolve or close an incident from the time it was reported.',
    formula: 'Average of (Resolution Time - Reported Time)',
    units: 'hrs',
  },
];

export const incidentRecurrenceRate = {
  id: 'RR',
  title: 'Incident Recurrence Rate',
  description:
    'Measures how often incidents of the same type or root cause reoccur within a set period.',
  formula: '(Recurrent Incidents / Total Incidents) * 100',
  units: '%',
};
export const assessmentKPIs = Object.values(assessmentTypes)
  .filter((a) => a.key !== 'OBSERVATION' && a.key !== 'AUDIT')
  .map((type) => ({
    filter: [{ field: 'inspectionType', operator: 'eq', value: type.key }],
    info: type,
  }));

export const chartViews = {
  ASSESSMENTS: {
    1: 'inspection_type',
    2: 'template_id',
    3: 'is_draft',
  },
  INCIDENTS: {
    1: 'type',
    2: 'sub_type',
    3: 'status',
    4: 'title',
  },
  OBSERVATIONS: { 1: 'type', 2: 'status', 3: 'title' },
  ACTION_ITEMS: { 1: 'status', 2: 'priority', 3: 'type' },
  CPAS: {
    1: 'risk_level',
    2: 'status',
  },
  AUDITS: {
    1: 'template_id',
    2: 'is_draft',
  },
};

export const getChartLabel = (status) => {
  if (!status) {
    return '';
  }

  switch (status) {
    case 'OBSERVATION':
      return 'OFI';
    case 'INITIATED':
      return `${i18n.t(keys.incidents.INITIATED)}`;
    case 'INVESTIGATION':
    case 'true':
    case true:
      return `${i18n.t(keys.common.IN_PROGRESS)}`;
    case 'COMPLETE':
    case 'false':
    case false:
      return `${i18n.t(keys.common.COMPLETE)}`;
    case 'PENDING':
      return `${i18n.t(keys.incidents.PENDING)}`;
    case 'CLOSED':
      return `${i18n.t(keys.incidents.CLOSED)}`;
    case 'INVALID':
      return `${i18n.t(keys.incidents.INVALID)}`;
    case 'POSITIVE':
      return `${i18n.t(keys.observations.POSITIVE)}`;
    case 'NEGATIVE':
      return `${i18n.t(keys.observations.ACTION_REQUIRED)}`;
    default:
      return i18n.t(getTranslationKey(status, 'common')) || status;
  }
};

export const statusStringConverter = (param, value) => {
  let string;
  switch (param) {
    case 'is_draft':
    case 'status':
      string = i18n.t(keys.common.STATUS);
      break;
    case 'inspection_type':
    case 'type':
      string = i18n.t(keys.common.TYPE);
      break;
    case 'sub_type':
      string = i18n.t(keys.common.SUBTYPE);
      break;
    default:
      return;
  }
  return { [string]: `${getChartLabel(value)}` };
};

export const actionItemKPIs = [
  {
    id: 'TTC',
    title: 'Average Time to Completion',
    description:
      'Represents the average duration taken to complete action items from their creation date to completion.',
    formula: 'Total Days to Complete / Number of Completed Action Items',
    units: 'days',
  },
  {
    id: 'OVERDUE',
    title: 'Action Items Overdue',
    description:
      'The number of action items that have surpassed their assigned deadline without being completed.',
    formula: 'Count of Items Past Due Date',
    units: 'items',
  },
  {
    id: 'CLOSED',
    title: 'Percentage Closed',
    description:
      'The ratio of completed action items to the total number of action items, expressed as a percentage.',
    formula: '(Number of Completed Items / Total Number of Items) * 100',
    units: '%',
  },
  {
    id: 'HIGH',
    title: 'Percentage of High Priority Action Items',
    description:
      'Indicates the proportion of action items identified as high priority.',
    formula:
      '(Number of High Priority Items / Total Number of Action Items) * 100',
    units: '%',
  },
  {
    id: 'MEDIUM',
    title: 'Percentage of Medium Priority Action Items',
    description:
      'Indicates the proportion of action items identified as medium priority.',
    formula:
      '(Number of Medium Priority Items / Total Number of Action Items) * 100',
    units: '%',
  },
  {
    id: 'LOW',
    title: 'Percentage of Low Priority Action Items',
    description:
      'Indicates the proportion of action items identified as low priority.',
    formula:
      '(Number of Low Priority Items / Total Number of Action Items) * 100',
    units: '%',
  },
  {
    id: 'RECURRING',
    title: 'Number of Recurring Issues',
    description:
      'Indicates the total number of recurring issues that have been identified through action items.',
  },
];
export const options = [
  { value: 'last30', label: 'Last 30 days' },
  { value: 'last90', label: 'Last 90 days' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'yearly', label: 'Annual' },
  { value: 'custom', label: 'Custom' },
];
export const optionsLookup = {
  last30: 'Last 30 days',
  last90: 'Last 90 days',
  monthly: 'Monthly',
  yearly: 'Annual',
  custom: 'Custom',
};

export const prevLookup = {
  last30: '30 days prior',
  last90: '90 days prior',
  monthly: 'Previous Month',
  yearly: 'Previous Year',
  custom: 'Previous Custom Timeframe',
};

export const reportingInfo = [
  {
    title: 'Report Type',
    description:
      'Identifies the category or nature of the reports being tracked.',
  },
  {
    title: 'Current Count',
    description:
      'Displays the total number of reports generated in the selected timeframe.',
  },
  {
    title: 'Average Per User',
    description:
      'Shows the average number of reports generated by each user within this period.',
  },
  {
    title: 'Difference in Count',
    description:
      'Indicates how the total report count has changed compared to the previous selected timeframe.',
  },
  {
    title: 'Difference in Average',
    description:
      'Highlights any changes in the average reports per user from the previous timeframe.',
  },
];
