import React, { useMemo, useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import SimpleUserCard from '../../components/SimpleUserCard.jsx';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../constants/strings.js';
import { Icon } from '@mui/material';
import { useWorkspace } from '../../providers/WorkspaceProvider.jsx';
import { getRoute } from '../../constants/strings.js';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys.js';
import { useTableComponents } from '../../components/tables/MTableComponents.jsx';
import MTTable from '../../components/tables/MTable.jsx';
import { useURLParams } from '../../providers/URLParamProvider.jsx';
import { CustomDateRangeFilter } from '../../components/tables/MTableComponents.jsx';
import { useQueryParams } from '../../hooks/misc.js';
import { useCurrentUser } from '../../providers/UserProvider.jsx';

const inspectionsQuery = loader('../../pages/fleet/InspectionsList.graphql');

export default function IncidentReportList() {
  const { availableWorkspaces, workspaceId: providerWorkspace } =
    useWorkspace();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { filters, page, pageSize, sort, subfilters } = useURLParams();
  const { user } = useCurrentUser();

  const { getParam } = useQueryParams();
  const [dateSelectors, setDateSelectors] = useState({
    dateCreatedMin: getParam('dateCreated')?.split('__')[0] || '',
    dateCreatedMax: getParam('dateCreated')?.split('__')[1] || '',
    dateCompletedMin: getParam('dateCreated')?.split('__')[0] || '',
    dateCompletedMax: getParam('dateCreated')?.split('__')[1] || '',
    dateModifiedMin: getParam('dateModified')?.split('__')[0] || '',
    dateModifiedMax: getParam('dateModified')?.split('__')[1] || '',
  });

  const workspaces = availableWorkspaces?.map((w) => ({
    label: `${w.title}`,
    value: `${w.id}`,
  }));
  workspaces.push({ label: `${t(keys.common.NONE)}`, value: 'null' });

  const {
    labelCell,
    userFilterOptions,
    dateCell,
    workspaceCell,
    textCell,
    externalIdCell,
  } = useTableComponents();

  const { loading, data: { inspections = [], inspectionsCount = 0 } = {} } =
    useQuery(inspectionsQuery, {
      skip: !page || !sort || !pageSize,
      variables: {
        options: {
          page,
          pageSize,
          sort,
          filters: [
            ...filters?.filter((f) => f.field !== 'type'),
            { field: 'inspectionType', operator: 'eq', value: 'INCIDENT' },
          ],
          subfilters,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'inspectionType',
        header: '',
        size: 70,
        enableColumnFilter: false,
        enableSorting: false,

        Cell: () => (
          <Icon baseClassName="material-icons-outlined" sx={{ color: 'red' }}>
            fmd_bad
          </Icon>
        ),
      },
      {
        accessorFn: (row) => row?.template?.title,
        accessorKey: 'template.title',
        header: t(keys.common.TITLE),
        grow: true,
        enableSorting: false,
        minSize: 250,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorFn: (row) => row?.incident?.externalId,
        header: user.company?.incidentName || t(keys.common.INCIDENT),
        Cell: ({ cell, row }) =>
          externalIdCell({
            externalId: cell.getValue(),
            id: row.original?.incident?.id,
          }),
      },
      {
        accessorKey: 'creator',
        header: t(keys.common.CREATOR),
        grow: true,
        size: 150,
        enableSorting: false,
        filterVariant: 'select',
        filterSelectOptions: userFilterOptions('workspace'),
        Cell: ({ cell }) => <SimpleUserCard size="sm" user={cell.getValue()} />,
      },
      {
        accessorKey: 'isDraft',
        header: t(keys.common.STATUS),
        Header: ({ column }) => (
          <div style={{ textWrap: 'nowrap' }}>{column.columnDef.header}</div>
        ),
        size: 150,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: [
          { value: 'true', label: t(keys.common.IN_PROGRESS) },
          { value: 'false', label: t(keys.common.COMPLETE) },
        ],
        Cell: ({ cell }) =>
          labelCell({
            name: !!cell.getValue()
              ? t(keys.common.IN_PROGRESS)
              : t(keys.common.COMPLETE),
            color: !!cell.getValue() ? 'yellow' : 'green',
          }),
      },
      {
        accessorKey: 'workspace',
        header: t(keys.common.WORKSPACE),
        enableSorting: false,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: workspaces,
        Cell: ({ cell }) => workspaceCell({ cell }),
      },
      {
        accessorKey: 'dateModified',
        header: t(keys.common.LAST_UPDATED),
        size: 275,
        Cell: ({ cell }) => dateCell({ cell }),
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateModifiedMin}
            maxDate={dateSelectors.dateModifiedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateModifiedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateModifiedMax: value })
            }
          />
        ),
      },
      {
        accessorKey: 'dateCreated',
        header: t(keys.common.DATE_CREATED),
        size: 275,
        Cell: ({ cell }) => dateCell({ cell }),
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateCreatedMin}
            maxDate={dateSelectors.dateCreatedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMax: value })
            }
          />
        ),
      },
      {
        accessorKey: 'dateCompleted',
        header: t(keys.common.DATE_COMPLETED),
        size: 275,
        Cell: ({ cell }) => dateCell({ cell }),
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateCompletedMin}
            maxDate={dateSelectors.dateCopmletedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCompletedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCopmletedMax: value })
            }
          />
        ),
      },
    ],
    [
      t,
      user.company?.incidentName,
      userFilterOptions,
      workspaces,
      textCell,
      externalIdCell,
      labelCell,
      workspaceCell,
      dateCell,
      dateSelectors,
    ],
  );

  return (
    <MTTable
      isLoading={loading}
      rightClickNavigation={paths.assessment}
      columns={columns}
      onRowClick={(assessment) => {
        const workspaceId = assessment?.workspaceId ?? providerWorkspace;
        navigate(getRoute(workspaceId, paths.assessment, assessment.id));
      }}
      data={inspections || []}
      rowCount={inspectionsCount}
      onClearAllFilters={() => {
        setDateSelectors({});
      }}
    />
  );
}
