import styles from './EditCompany.module.scss';
import { Col, Form, Row } from 'react-bootstrap';
import { Text } from '../../components/typography';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useFlags } from 'launchdarkly-react-client-sdk';

export default function EditCompanySettings({ setCompany, company }) {
  const { auditsAndCorrectiveActions } = useFlags();
  const { t } = useTranslation();

  return (
    <div>
      <Text size="lg" weight="semiBold" noMargin>
        Module Name Details
      </Text>
      <Text size="sm" color="secondary">
        This allows you to customize the names of modules
      </Text>
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Text className={styles.label} noMargin weight="bold">
            {t(keys.observations.OBSERVATIONS)}
          </Text>
          <Form.Control
            required
            type="text"
            placeholder={t(keys.action.ENTER, {
              variable: t(keys.settings.OBSERVATIONNAVINFO),
            })}
            value={company?.observationName || undefined}
            onChange={(e) =>
              setCompany({ ...company, observationName: e.target.value })
            }
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Text className={styles.label} noMargin weight="bold">
            {t(keys.toolbox.TOOLBOX)}
          </Text>
          <Form.Control
            required
            type="text"
            placeholder={t(keys.action.ENTER, {
              variable: t(keys.settings.TOOLBOXNAVINFO),
            })}
            value={company?.toolboxName || undefined}
            onChange={(e) =>
              setCompany({ ...company, toolboxName: e.target.value })
            }
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Text className={styles.label} noMargin weight="bold">
            {t(keys.incidents.INCIDENTS)}
          </Text>
          <Form.Control
            required
            type="text"
            placeholder={t(keys.action.ENTER, {
              variable: t(keys.settings.INCIDENTNAVINFO),
            })}
            value={company?.incidentName || undefined}
            onChange={(e) =>
              setCompany({ ...company, incidentName: e.target.value })
            }
          />
        </Form.Group>
      </Row>
      {auditsAndCorrectiveActions ? (
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Text className={styles.label} noMargin weight="bold">
              Audits
            </Text>
            <Form.Control
              required
              type="text"
              name="audits"
              placeholder={t(keys.action.ENTER, {
                variable: t(keys.settings.AUDIT_NAVIGATION),
              })}
              value={company?.auditName || undefined}
              onChange={(e) =>
                setCompany({ ...company, auditName: e.target.value })
              }
            />
          </Form.Group>
        </Row>
      ) : null}
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Text className={styles.label} noMargin weight="bold">
            Assessments
          </Text>
          <Form.Control
            required
            type="text"
            name="assessments"
            placeholder={t(keys.action.ENTER, {
              variable: 'Assessment Name',
            })}
            value={company?.assessmentName || undefined}
            onChange={(e) =>
              setCompany({ ...company, assessmentName: e.target.value })
            }
          />
        </Form.Group>
      </Row>
    </div>
  );
}
