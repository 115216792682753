import styles from './Audits.module.scss';
import { Text } from '../../components/typography';
import Button from '../../components/Button';
import AuditsTable from './AuditsTable';
import AuditTemplates from './AuditTemplates';
import TabSystem from '../../components/TabSystem';
import { useQueryParams } from '../../hooks/misc';
import { searchParamKeys } from '../../constants/strings';
import { useModal } from '../../providers/ModalProvider';
import { assessmentTypes } from '../../constants/strings';
import { modals } from '../../providers/modals';
import { useCurrentUser } from '../../providers/UserProvider';
import CPATable from './CPATable';

const defaultTab = 'audits';

export default function Audits() {
  const { getParam } = useQueryParams();
  const { user } = useCurrentUser();
  const { openModal } = useModal();
  const tab = getParam(searchParamKeys.tab, defaultTab);
  const options = [
    {
      title: 'Audits',
      element: AuditsTable,
      key: 'audits',
    },
    {
      title: 'Checklists',
      element: AuditTemplates,
      key: 'templates',
    },
    {
      title: 'CPAs',
      element: CPATable,
      key: 'cpas',
    },
  ];
  const buttonText = { audits: 'Audit', templates: 'Checklist', cpas: 'CPA' };
  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <Text size="lg" weight="bold">
          {user.company?.auditName || 'Audits'}
        </Text>
        <Button
          value={`New ${buttonText[tab]}`}
          icon="add"
          onClick={() => {
            switch (tab) {
              case 'audits':
                openModal({
                  modalName: modals.audit,
                  variables: {
                    participants: [user],
                    type: assessmentTypes.audit,
                  },
                });
                break;
              case 'templates':
                openModal({
                  modalName: modals.createTemplate,
                  variables: {
                    modalTitle: 'Create Checklist',
                    types: [assessmentTypes.audit],
                    type: assessmentTypes.audit,
                    allWorkspaces: true,
                    options: {
                      allWorkspaces: false,
                      secondSight: false,
                      approvalRequired: false,
                    },
                  },
                });
                break;
              case 'cpas':
                openModal({
                  modalName: modals.CPA,
                  variables: {
                    navigation: true,
                  },
                });
                break;
              default:
                throw new Error(`Invalid type ${tab}`);
            }
          }}
        />
      </div>
      <TabSystem type={tab} options={options} />
    </div>
  );
}
