import { Text, View, Document, Page, Link } from '@react-pdf/renderer';
import { PageHeaderLogo, styles, PageHeader, Comment } from '../constants';
import { textStyles } from '../styles';
import {
  rootCauseMap,
  cpaStringLookup,
  riskLevels,
  cpaStatusTypes,
} from '../../audits';
import { customColors } from '../..';
import { getLocalTime } from '../../time';
import { keys } from '../../translator/translation_keys';
import { useTranslation } from 'react-i18next';
import { Extension } from './extension';

export const CorrectiveActionDocument = ({ correctiveAction, company }) => {
  const {
    inspection,
    question,
    riskLevel,
    probability,
    impact,
    ACCEPT,
    FINAL,
    INTERIM,
    REVIEW,
    rootCause,
    rootCauseAnalysis,
    isObservation,
    creator,
    status,
    dateCreated,
    narrative,
    attachments,
  } = correctiveAction || {};

  const { t } = useTranslation();

  const actionItems = [INTERIM, FINAL, ACCEPT, REVIEW] || [];

  const color = riskLevels[riskLevel]?.color?.toUpperCase() || 'primary';

  return (
    !!correctiveAction && (
      <Document>
        <Page size="A4" style={styles.page}>
          <PageHeaderLogo company={company} workspace={null} />
          <PageHeader
            company={company}
            data={correctiveAction}
            title={correctiveAction.externalId}
            workspace={null}
            type="Corrective Action"
          />
          <Text
            style={textStyles.header}
          >{`Corrective Action ${correctiveAction.externalId}`}</Text>
          <View>
            <Text style={textStyles.leftText}>{`Status: ${
              t(cpaStatusTypes[status]?.title) || status
            }`}</Text>
            <Text
              style={[textStyles.question, { color: customColors.SECONDARY }]}
            >{`${creator.firstName} ${creator.lastName} ${getLocalTime(
              dateCreated,
            ).format('dddd MMMM Do YYYY')}`}</Text>
          </View>

          {!!inspection ? (
            <Text style={textStyles.gray}>
              {`${inspection?.template?.title} - ${correctiveAction?.type}`}
            </Text>
          ) : null}
          {!!question ? (
            <View>
              <Text style={[textStyles.question, { color: customColors.RED }]}>
                Failed Question
              </Text>
              <Text style={textStyles.questionLg}>{question.title}</Text>
            </View>
          ) : null}
          <Text style={textStyles.question}>{narrative}</Text>
          {!isObservation ? (
            <View>
              <Text style={styles.categoryHeader}>Risk Assessment</Text>
              <View style={textStyles.spacer} />
              <Text
                style={[textStyles.questionLg, { color: customColors[color] }]}
              >
                {`${riskLevel} ${!isObservation ? 'RISK' : ''}`}
              </Text>
              <Text style={textStyles.question}>{`Impact: ${impact}/5`}</Text>
              <Text
                style={textStyles.question}
              >{`Probability: ${probability}/5`}</Text>
            </View>
          ) : null}
          <Text style={styles.categoryHeader}>Root Cause</Text>
          <View style={textStyles.spacer} />
          <Text style={textStyles.question}>
            {rootCauseMap[rootCause] || 'No Root Cause Provided'}
          </Text>
          <Text style={textStyles.answer}>{rootCauseAnalysis || ''}</Text>
          {actionItems?.map((action) => {
            const {
              type,
              outcome,
              assignee,
              comments,
              participants,
              dueDate,
              dateCompleted,
              extension,
            } = action || {};
            return !!action ? (
              <View key={action.id}>
                <Text style={styles.categoryHeader}>
                  {type ? cpaStringLookup[type] : ''}
                </Text>
                <View style={textStyles.rowNoBorder}>
                  <View style={textStyles.leftText}>
                    <Text style={textStyles.question}>
                      {`Due Date: ${
                        dueDate
                          ? getLocalTime(dueDate).format('dddd MMMM Do YYYY')
                          : 'None'
                      }`}
                    </Text>
                    <Text style={textStyles.question}>
                      {`Date Completed: ${
                        dateCompleted
                          ? getLocalTime(dateCompleted).format(
                              'dddd MMMM Do YYYY',
                            )
                          : 'None'
                      }`}
                    </Text>
                    <View style={textStyles.spacer} />
                    <Text style={textStyles.answer}>{outcome}</Text>
                    {!!comments?.length ? (
                      <View>
                        <View style={textStyles.spacer} />
                        {comments?.map((comment) => (
                          <Comment key={comment.id} comment={comment} />
                        ))}
                      </View>
                    ) : null}
                  </View>
                  <View>
                    <Text style={textStyles.rightText}>Assignee</Text>
                    <Text style={textStyles.rightTextRegular}>
                      {`${assignee?.firstName} ${assignee?.lastName}`}
                    </Text>
                    {!!participants?.length ? (
                      <View>
                        <Text style={textStyles.rightText}>Delegates</Text>
                        {participants?.map((p) => (
                          <Text
                            key={p.id}
                            style={textStyles.rightTextRegular}
                          >{`${p.firstName} ${p.lastName}`}</Text>
                        ))}
                      </View>
                    ) : null}
                    <Extension extensions={extension} />
                  </View>
                </View>
              </View>
            ) : null;
          })}
          <Text style={textStyles.categoryHeader}>Attachments</Text>
          <View style={textStyles.spacer} />
          {attachments?.length ? (
            <Link style={styles.link}></Link>
          ) : (
            <Text style={textStyles.leftText}>{t(keys.common.NONE)}</Text>
          )}
        </Page>
      </Document>
    )
  );
};
