import React, { useMemo, useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import SimpleUserCard from '../../components/SimpleUserCard';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../constants/strings';
import { assessmentTypes } from '../../constants/strings';
import { Icon } from '@mui/material';
import { useWorkspace } from '../../providers/WorkspaceProvider.jsx';
import { getRoute } from '../../constants/strings';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys.js';
import { getColor } from '../../constants/strings';
import { useTableComponents } from '../../components/tables/MTableComponents.jsx';
import MTTable from '../../components/tables/MTable.jsx';
import { useURLParams } from '../../providers/URLParamProvider.jsx';
import { CustomDateRangeFilter } from '../../components/tables/MTableComponents.jsx';
import { useQueryParams } from '../../hooks/misc.js';
import { useLabels } from '../../providers/LabelProvider.jsx';
import { LabelFilter } from '../../components/tables/MTableComponents.jsx';
import { Form } from 'react-bootstrap';
import Text from '../../components/typography/Text.jsx';
import styles from './InspectionsList.module.scss';
import { getAuditStatus } from '../../utilities/audits.js';

const inspectionsQuery = loader('./InspectionsList.graphql');

export default function InspectionsList() {
  const {
    workspaceVehicles,
    availableWorkspaces,
    workspaceId: providerWorkspace,
  } = useWorkspace();
  const { labels } = useLabels();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { filters, page, pageSize, sort, subfilters } = useURLParams();

  const { getParam, deleteParam, upsertParam } = useQueryParams();
  const [dateSelectors, setDateSelectors] = useState({
    dateCreatedMin: getParam('dateCreated')?.split('__')[0] || '',
    dateCreatedMax: getParam('dateCreated')?.split('__')[1] || '',
    dateCompletedMin: getParam('dateCreated')?.split('__')[0] || '',
    dateCompletedMax: getParam('dateCreated')?.split('__')[1] || '',
    dateModifiedMin: getParam('dateModified')?.split('__')[0] || '',
    dateModifiedMax: getParam('dateModified')?.split('__')[1] || '',
  });
  const inspectionType = getParam('inspectionType');

  const workspaces = availableWorkspaces?.map((w) => ({
    label: `${w.title}`,
    value: `${w.id}`,
  }));
  workspaces.push({ label: `${t(keys.common.NONE)}`, value: 'null' });

  const {
    multiLabelCell,
    labelCell,
    userFilterOptions,
    dateCell,
    assetCell,
    assetFilterOptions,
    workspaceCell,
    textCell,
  } = useTableComponents();

  const allWorkspaces = useMemo(() => {
    const allWorkspaces = getParam('allWorkspaces');
    if (allWorkspaces === 'TRUE') {
      return true;
    }
    return false;
  }, [getParam]);

  const allFilters = useMemo(() => {
    return [
      ...filters,
      {
        field: 'inspectionType',
        operator: !!inspectionType ? 'eq' : 'not',
        value: !!inspectionType
          ? `${inspectionType}`
          : ['INCIDENT', 'AUDIT', 'OBSERVATION'],
      },
    ];
  }, [filters, inspectionType]);

  const [selectedLabels, setSelectedLabels] = useState(
    getParam('labels')
      ?.split(',')
      .map((n) => labels.find(({ id }) => id === parseInt(n))) || [],
  );

  const { loading, data: { inspections = [], inspectionsCount = 0 } = {} } =
    useQuery(inspectionsQuery, {
      skip: !page || !sort || !pageSize,
      variables: {
        options: {
          page,
          pageSize,
          sort,
          filters: [...allFilters],
          subfilters,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'inspectionType',
        header: t(keys.common.TYPE),
        size: 100,
        filterVariant: 'select',
        filterSelectOptions: [
          ...Object.keys(assessmentTypes)
            .filter((f) => f !== 'observation' && f !== 'incident')
            .map((type) => ({
              value: assessmentTypes[type].key,
              label: t(assessmentTypes[type].title),
            })),
        ],
        Cell: ({ cell }) => (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{
              fontSize: '1.25rem',
              color: getColor(cell?.getValue()?.toLowerCase()),
            }}
          >
            {assessmentTypes[cell.getValue().toLowerCase()]?.icon ||
              'local_shipping'}
          </Icon>
        ),
      },
      {
        accessorFn: (row) => row?.template?.title,
        accessorKey: 'template.title',
        header: t(keys.common.TITLE),
        grow: true,
        enableSorting: false,
        minSize: 250,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'creator',
        header: t(keys.common.CREATOR),
        grow: true,
        size: 150,
        enableSorting: false,
        filterVariant: 'select',
        filterSelectOptions: userFilterOptions('workspace'),
        Cell: ({ cell }) => <SimpleUserCard size="sm" user={cell.getValue()} />,
      },
      {
        accessorKey: 'isDraft',
        header: t(keys.common.STATUS),
        Header: ({ column }) => (
          <div style={{ textWrap: 'nowrap' }}>{column.columnDef.header}</div>
        ),
        size: 150,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: [
          { value: 'true', label: t(keys.common.IN_PROGRESS) },
          { value: 'false', label: t(keys.common.COMPLETE) },
        ],
        Cell: ({ row }) => {
          const label = getAuditStatus({
            inspection: row.original,
            actionItems: row.original.actionItems,
          });
          return labelCell({
            name: t(label.title),
            color: label.color,
          });
        },
      },
      {
        accessorFn: (row) => row.passed,
        header: t(keys.common.PASSED),
        size: 100,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: [
          { value: 'true', label: t(keys.common.PASSED) },
          { value: 'false', label: t(keys.common.FAILED) },
        ],
        Cell: ({ cell }) => (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{
              color: cell.getValue() ? '#28a745' : '#e9222c',
              fontSize: '1.25rem',
            }}
          >
            {cell.getValue() ? 'check' : 'close'}
          </Icon>
        ),
      },
      {
        accessorKey: 'documentNumber',
        header: t(keys.assessments.DOCUMENT_NUMBER),
        grow: true,
        enableSorting: false,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'vehicle',
        header: t(keys.common.ASSET),
        grow: true,
        enableSorting: false,
        filterVariant: 'select',
        filterSelectOptions: assetFilterOptions(workspaceVehicles),
        Cell: ({ cell }) => assetCell({ cell }),
      },
      {
        accessorKey: 'workspace',
        header: t(keys.common.WORKSPACE),
        enableSorting: false,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: workspaces,
        Cell: ({ cell }) => workspaceCell({ cell }),
      },
      {
        accessorKey: 'dateModified',
        header: t(keys.common.LAST_UPDATED),
        size: 275,
        Cell: ({ cell }) => dateCell({ cell }),
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateModifiedMin}
            maxDate={dateSelectors.dateModifiedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateModifiedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateModifiedMax: value })
            }
          />
        ),
      },
      {
        accessorKey: 'dateCreated',
        header: t(keys.common.DATE_CREATED),
        size: 275,
        Cell: ({ cell }) => dateCell({ cell }),
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateCreatedMin}
            maxDate={dateSelectors.dateCreatedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMax: value })
            }
          />
        ),
      },
      {
        accessorKey: 'dateCompleted',
        header: t(keys.common.DATE_COMPLETED),
        size: 275,
        Cell: ({ cell }) => dateCell({ cell }),
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateCompletedMin}
            maxDate={dateSelectors.dateCopmletedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCompletedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCopmletedMax: value })
            }
          />
        ),
      },
      {
        accessorKey: 'labels',
        header: t(keys.common.LABELS),
        enableSorting: false,
        size: 300,
        Filter: ({ column }) => (
          <LabelFilter
            column={column}
            selectedLabels={selectedLabels}
            labels={labels}
            handleChange={(label) => {
              setSelectedLabels(label);
            }}
            handleRemove={() => {
              column.setFilterValue(null);
              setSelectedLabels([]);
            }}
          />
        ),
        Cell: ({ cell }) => {
          const labels = cell.getValue() || [];
          return multiLabelCell({ labels });
        },
      },
    ],
    [
      workspaceVehicles,
      assetFilterOptions,
      t,
      userFilterOptions,
      workspaces,
      labelCell,
      dateCell,
      assetCell,
      dateSelectors,
      selectedLabels,
      labels,
      multiLabelCell,
      workspaceCell,
      textCell,
    ],
  );

  return (
    <MTTable
      isLoading={loading}
      rightClickNavigation={paths.assessment}
      columns={columns}
      data={inspections || []}
      rowCount={inspectionsCount}
      onRowClick={(assessment) => {
        const workspaceId = assessment?.workspaceId ?? providerWorkspace;
        navigate(getRoute(workspaceId, paths.assessment, assessment.id));
      }}
      onClearAllFilters={() => {
        setSelectedLabels([]);
        setDateSelectors({});
      }}
      customToolbar={
        <div className={styles.check}>
          <Form.Check
            size={'small'}
            checked={allWorkspaces}
            onChange={(e) => {
              if (e.target.checked) {
                upsertParam('allWorkspaces', 'TRUE');
              } else {
                deleteParam('allWorkspaces');
              }
            }}
          />
          <Text noMargin size="sm">
            {t(keys.templates.ALL_WORKSPACES)}
          </Text>
        </div>
      }
    />
  );
}
