import { useMemo, useState } from 'react';
import { cpaKPIs } from '../../../utilities/analytics';
import styles from './Statistics.module.scss';
import StatBox from './StatBox';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import differenceInDays from 'date-fns/differenceInDays';
import subDays from 'date-fns/subDays';
import { customColors } from '../../../utilities';
import TextWithIcon from '../../../components/typography/TextWithIcon';

const query = loader('./CorrectiveActionStatistics.count.graphql');

export default function CorrectiveActionStatistics({
  start,
  end,
  workspaceIds,
}) {
  const [show, setShow] = useState(false);
  const daysInRange = differenceInDays(new Date(end), new Date(start));
  const previousEnd = subDays(new Date(start), 1);
  const previousStart = subDays(previousEnd, daysInRange);

  const useCount = ({ startDate, endDate, additionalFilters = [], type }) => {
    const value = startDate && endDate ? [startDate, endDate] : endDate;
    const operator = startDate && endDate ? 'between' : 'lte';
    const { data } = useQuery(query, {
      variables: {
        workspaceIds,
        type,
        options: {
          filters: [
            ...additionalFilters,
            {
              field: 'dateCreated',
              operator,
              value,
            },
          ],
        },
      },
    });
    return data?.correctiveActionStatisticsCount || 0;
  };

  const ICA = useCount({
    startDate: start,
    endDate: end,
    type: 'ICA',
  });
  const ICA_prev = useCount({
    startDate: previousStart,
    endDate: previousEnd,
    type: 'ICA',
  });

  const FPA = useCount({
    startDate: start,
    endDate: end,
    type: 'FPA',
  });
  const FPA_prev = useCount({
    startDate: previousStart,
    endDate: previousEnd,
    type: 'FPA',
  });
  const EXT = useCount({ startDate: start, endDate: end, type: 'EXT' });
  const EXT_prev = useCount({
    startDate: previousStart,
    endDate: previousEnd,
    type: 'EXT',
  });

  const HIGH = useCount({
    startDate: start,
    endDate: end,
    additionalFilters: [{ field: 'riskLevel', operator: 'eq', value: 'HIGH' }],
  });
  const HIGH_prev = useCount({
    startDate: previousStart,
    endDate: previousEnd,
    additionalFilters: [{ field: 'riskLevel', operator: 'eq', value: 'HIGH' }],
  });

  const TOTAL_FINDINGS = useCount({
    startDate: start,
    endDate: end,
  });
  const TOTAL_FINDINGS_PREV = useCount({
    startDate: previousStart,
    endDate: previousEnd,
  });

  const MEDIUM = useCount({
    startDate: start,
    endDate: end,
    additionalFilters: [
      { field: 'riskLevel', operator: 'eq', value: 'MEDIUM' },
    ],
  });
  const MEDIUM_prev = useCount({
    startDate: previousStart,
    endDate: previousEnd,
    additionalFilters: [
      { field: 'riskLevel', operator: 'eq', value: 'MEDIUM' },
    ],
  });
  const LOW = useCount({
    startDate: start,
    endDate: end,
    additionalFilters: [{ field: 'riskLevel', operator: 'eq', value: 'LOW' }],
  });
  const LOW_prev = useCount({
    startDate: previousStart,
    endDate: previousEnd,
    additionalFilters: [{ field: 'riskLevel', operator: 'eq', value: 'LOW' }],
  });
  const OFI = useCount({
    startDate: start,
    endDate: end,
    additionalFilters: [
      { field: 'isObservation', operator: 'eq', value: `${true}` },
    ],
  });

  const OFI_prev = useCount({
    startDate: previousStart,
    endDate: previousEnd,
    additionalFilters: [
      { field: 'isObservation', operator: 'eq', value: `${true}` },
    ],
  });
  const REPEAT = useCount({
    startDate: start,
    endDate: end,
    additionalFilters: [
      { field: 'parentId', operator: 'not', value: `${null}` },
    ],
  });
  const REPEAT_prev = useCount({
    startDate: previousStart,
    endDate: previousEnd,
    additionalFilters: [
      { field: 'parentId', operator: 'not', value: `${null}` },
    ],
  });

  const cpaStats = useMemo(() => {
    return {
      ICA: {
        value: (ICA / TOTAL_FINDINGS) * 100,
        stat:
          (ICA / TOTAL_FINDINGS -
            (!!ICA_prev ? ICA_prev / TOTAL_FINDINGS_PREV : 0)) *
          100,
      },
      FPA: {
        value: (FPA / TOTAL_FINDINGS) * 100,
        stat:
          (FPA / TOTAL_FINDINGS -
            (!!FPA_prev ? FPA_prev / TOTAL_FINDINGS_PREV : 0)) *
          100,
      },
      EXT: { value: EXT, stat: EXT - EXT_prev },
      HIGH: {
        value: (HIGH / TOTAL_FINDINGS) * 100,
        stat:
          (HIGH / TOTAL_FINDINGS -
            (!!HIGH_prev ? HIGH_prev / TOTAL_FINDINGS_PREV : 0)) *
          100,
      },
      MEDIUM: {
        value: (MEDIUM / TOTAL_FINDINGS) * 100,
        stat:
          (MEDIUM / TOTAL_FINDINGS -
            (!!MEDIUM_prev ? MEDIUM_prev / TOTAL_FINDINGS_PREV : 0)) *
          100,
      },
      LOW: {
        value: (LOW / TOTAL_FINDINGS) * 100,
        stat:
          (LOW / TOTAL_FINDINGS -
            (!!LOW_prev ? LOW_prev / TOTAL_FINDINGS_PREV : 0)) *
          100,
      },
      OFI: {
        value: (OFI / TOTAL_FINDINGS) * 100,
        stat:
          (OFI / TOTAL_FINDINGS -
            (!!OFI_prev ? OFI_prev / TOTAL_FINDINGS_PREV : 0)) *
          100,
      },
      REPEAT: {
        value: REPEAT,
        stat: REPEAT - REPEAT_prev,
      },
    };
  }, [
    EXT,
    EXT_prev,
    FPA,
    FPA_prev,
    HIGH,
    HIGH_prev,
    ICA,
    ICA_prev,
    LOW,
    LOW_prev,
    MEDIUM,
    MEDIUM_prev,
    OFI,
    OFI_prev,
    REPEAT,
    REPEAT_prev,
    TOTAL_FINDINGS,
    TOTAL_FINDINGS_PREV,
  ]);

  return (
    <div className={styles.flexColumn}>
      <TextWithIcon
        icon="info"
        iconProps={{ color: customColors.BLUE }}
        onClick={() => setShow(!show)}
        noMargin
        weight="semiBold"
      >
        Statistics
      </TextWithIcon>
      <div className={styles.stats}>
        {cpaKPIs.map((kpi) => (
          <StatBox
            show={show}
            setShow={setShow}
            key={kpi.id}
            kpi={kpi}
            value={cpaStats[kpi.id]?.value}
            stat={cpaStats[kpi.id]?.stat}
            showUnits={true}
          />
        ))}
      </div>
    </div>
  );
}
