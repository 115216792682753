import { createContext, useContext } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useCurrentUser } from './UserProvider';

const inspectionsQuery = loader(
  'src/pages/inspection_page/Inspection.inspection.graphql',
);
const participantsQuery = loader('./MyInspections.participant.graphql');
const MyInspectionsProviderContext = createContext();

export const MyInspectionsProvider = ({ children }) => {
  const { user } = useCurrentUser();
  const client = useApolloClient();

  const { data: { inspections: myParticipatedInspections = [] } = {} } =
    useQuery(participantsQuery, {
      fetchPolicy: 'network-only',
      variables: { userId: `${user.id}` },
    });

  const { refetch, data: { inspections = [] } = {} } = useQuery(
    inspectionsQuery,
    {
      variables: {
        options: {
          filters: [
            { field: 'isArchived', operator: 'eq', value: ['FALSE'] },
            { field: 'creatorId', operator: 'eq', value: [`${user.id}`] },
          ],
          sort: [{ field: 'dateModified', order: 'desc' }],
          pageSize: 20,
        },
      },
      onCompleted: ({ inspections }) => {
        inspections.forEach((inspection) => {
          client.writeQuery({
            query: inspectionsQuery,
            data: {
              inspections: [inspection],
            },
            variables: {
              options: {
                filters: [
                  { field: 'id', value: `${inspection.id}`, operator: 'eq' },
                  { field: 'isArchived', operator: 'eq', value: ['FALSE'] },
                ],
              },
            },
          });
        });
      },
    },
  );

  return (
    <MyInspectionsProviderContext.Provider
      value={{
        myInspections: inspections,
        refetchMyInspections: refetch,
        myParticipatedInspections,
      }}
    >
      {children}
    </MyInspectionsProviderContext.Provider>
  );
};
export const useMyInspections = () => useContext(MyInspectionsProviderContext);
