import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import styles from './MultipleChoiceQuestion.module.scss';
import { Text } from './typography';
import Button from './Button';
import { Icon } from '@mui/material';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys';
import { useModal } from '../providers/ModalProvider';

const optionSetMuation = loader('./MultipleChoiceQuestion.create.graphql');
const optionMutation = loader('./MultipleChoiceQuestion.createOption.graphql');

export default function MultipleChoiceQuestion({
  setModalOpen,
  refetch,
  onSubmit,
}) {
  const { t } = useTranslation();

  const [addOptionSet] = useMutation(optionSetMuation);
  const [addOption] = useMutation(optionMutation);
  const { openConfirmationModal } = useModal();

  const [input, setInput] = useState('');
  const [optionSet, setOptionSet] = useState([]);
  const [sendingOptions, setSendingOptions] = useState(false);
  const [title, setTitle] = useState('');

  return (
    <div className={styles.titleContainer}>
      <Text size="md" weight="semiBold" color="primary" noSelect noMargin>
        {t(keys.common.TITLE)}
      </Text>
      <Form.Control
        placeholder={t(keys.templates.OPTIONS_TITLE_PLACEHOLDER)}
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />

      <Text size="md" weight="semiBold" color="primary" noSelect noMargin>
        {t(keys.common.OPTIONS)}
      </Text>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className={styles.optionSet}>
          <Form.Control
            placeholder={t(keys.templates.OPTION_SET_PLACEHOLDER)}
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />

          <Button
            className={styles.addButton}
            outlined={true}
            onClick={() => {
              setOptionSet([...optionSet, input]);
              setInput('');
            }}
            disabled={!input.length}
            value={t(keys.action.ADD, { variable: null })}
          />
        </div>
        <div className={styles.checkBoxDisplay}>
          {optionSet.map((option, index) => (
            <div className={styles.optionDisplay} key={index}>
              <Text noMargin>{option}</Text>
              <Icon
                className={styles.deselectIcon}
                fontSize="inherit"
                baseClassName="material-icons-outlined"
                onClick={() => {
                  const newSet = optionSet.filter((o) => o !== option);
                  setOptionSet(newSet);
                }}
              >
                close
              </Icon>
            </div>
          ))}
        </div>

        <div className={styles.buttonDisplay}>
          <Button
            variant="secondary"
            onClick={() => {
              setModalOpen(false);
            }}
            value={t(keys.action.CANCEL)}
          />
          <Button
            disabled={!optionSet.length || sendingOptions}
            onClick={() => {
              openConfirmationModal({
                variant: 'success',
                cancelText: 'No',
                buttonText: 'Yes',
                title: 'Save Options',

                description:
                  'Would you like to save this option set for future use?',
                onSubmit: async () => {
                  setSendingOptions(true);
                  await addOptionSet({ variables: { title } }).then(
                    ({
                      data: {
                        addOptionSet: { id },
                      },
                    }) => {
                      for (let i = 0; i < optionSet.length; i += 1) {
                        addOption({
                          variables: {
                            optionSetId: id,
                            text: optionSet[i],
                          },
                        });
                      }
                      refetch();
                    },
                  );
                },
              });
              onSubmit({
                title,
                options: optionSet.map((option) => ({ text: option })),
              });
              setSendingOptions(false);
              setOptionSet([]);
              setInput('');
              setModalOpen(false);
            }}
            value={t(keys.action.SUBMIT)}
          />
        </div>
      </Form>
    </div>
  );
}
