import styles from './SectionContainer.module.scss';
import { Text } from '../../../components/typography';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';
import Icon from '../../../components/Icon';
import classNames from 'classnames';

export default function SectionContainer({
  title,
  children,
  onClick,
  icon,
  className,
  isLoading = false,
  actionText,
  hasChildren = true,
  emptyState,
  footer,
  options,
  setSelected,
  selected,
}) {
  const { t } = useTranslation();
  return isLoading ? (
    <></>
  ) : (
    <div className={classNames(styles.container, className)}>
      <div className={styles.title}>
        <Text noMargin weight="semiBold" size="lg">
          {title}
        </Text>
        <Icon size="lg" color="secondary">
          {icon}
        </Icon>
      </div>
      <div className={styles.header}>
        <div>
          {options?.length && (
            <div className={styles.links}>
              {options?.map((o) => (
                <Text
                  hover
                  className={selected === o ? styles.selected : styles.regular}
                  onClick={() => setSelected(o)}
                  noMargin
                  weight={selected === o ? 'bold' : 'regular'}
                  color="accentPrimary"
                >
                  {o}
                </Text>
              ))}
            </div>
          )}
        </div>
        <div>
          {!!onClick && hasChildren && (
            <Text
              weight="semiBold"
              noMargin
              color="accentPrimary"
              hover
              onClick={onClick}
            >
              {actionText || t(keys.dashboard.SEE_ALL)}
            </Text>
          )}
        </div>
      </div>
      <div className={classNames(styles.content, styles[className])}>
        {hasChildren ? children : emptyState}
      </div>
      <div className={styles.footer}>{footer}</div>
    </div>
  );
}
