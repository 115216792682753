import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import {
  PageHeader,
  PageFooter,
  styles,
  PageHeaderLogo,
  CorrectiveActions,
} from '../constants';
import { Notes } from '../constants';
import { VehicleCard } from '../vehicle';
import { Category } from './../assessment_components/assessment_category';
import { Signatures } from './../assessment_components/assessment_signatures';
import { textStyles } from '../styles';
import { ExecutiveSummaryDocument } from './executive_summary';

export const AuditDocument = ({
  inspection,
  correctiveActions,
  notes,
  props = {},
}) => {
  const company = inspection?.creator?.company;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PageHeaderLogo company={company} workspace={null} />
        <PageHeader
          company={company}
          data={inspection}
          title={inspection.template.title}
          workspace={null}
          type="Audit"
        />
        <View>
          <Text
            style={styles.titleHeader}
          >{`${inspection.template.title} - ${inspection.auditType} Audit`}</Text>
          <Text style={styles.headerText}>
            {inspection.template.description}
          </Text>
        </View>
        {!!inspection.vehicle ? (
          <VehicleCard vehicle={inspection?.vehicle} />
        ) : null}
        {!!props.summary && (
          <ExecutiveSummaryDocument
            inspection={inspection}
            company={company}
            correctiveActions={correctiveActions}
            showDetails={false}
            showHeader={false}
          />
        )}
        {props.checklist && (
          <View break={!!props.summary}>
            <Text
              style={styles.titleHeader}
            >{`${inspection.template.title} Audit Checklist`}</Text>
            <View style={styles.categoryContainer}>
              {inspection.template.categories.map((category, index) => (
                <Category
                  key={`${category.id}-${index}`}
                  category={category.title}
                  categoryId={category.id}
                  isBundle={category.isBundle}
                  questions={category.questions}
                  answers={inspection.items}
                  isAudit={true}
                  correctiveActions={correctiveActions}
                  showComments={!!props.comments}
                />
              ))}
            </View>
          </View>
        )}
        {!!props.actions && (
          <View break={!!props.checklist} style={styles.categoryContainer}>
            <Text style={textStyles.categoryHeader}>
              Corrective Preventative Action Summary
            </Text>
            {correctiveActions?.map((c) => (
              <CorrectiveActions correctiveAction={c || []} key={c.id} />
            ))}
          </View>
        )}
        {!!props.notes && (
          <View>
            <Notes notes={notes} style={styles.categoryContainer} />
            <Text style={styles.text}>{inspection.notes}</Text>
          </View>
        )}

        {!!props.signatures && !!inspection.attachments?.length && (
          <Signatures
            signatures={
              inspection.attachments?.filter(
                (a) => a.extension === 'SIGNATURE',
              ) || []
            }
          />
        )}
        <PageFooter />
      </Page>
    </Document>
  );
};
