export const dropdownConstants = {
  Template: { icon: 'description' },
  Asset: { icon: 'local_shipping' },
  Workspace: { icon: 'description' },
  Activity: { icon: 'work' },
  Icons: { icon: 'mood' },
  Tasks: { icon: 'assignment_turned_in' },
};
export const dropdownTypes = {
  ASSET: 'Asset',
  ACTIVITY: 'Activity',
  WORKSPACE: 'Workspace',
  TEMPLATE: 'Template',
  ACTIVITIES: 'Activities',
  TASKS: 'Tasks',
  ICONS: 'Icons',
  ROLES: 'Roles',
};
