import styles from './ObservationModal.module.scss';
import Modal from '../Modal';
import { useModal } from '../../../providers/ModalProvider';
import { modals } from '../../../providers/modals';
import { Text } from '../../typography';
import { Form, Spinner } from 'react-bootstrap';
import Button from '../../Button';
import UserMultiSelector from '../../UserMultiSelector';
import { useState } from 'react';
import { openFileViewer, showToast } from '../../../graphql/cache/modal';
import { toastLength, toastVariant } from '../../../constants/misc';
import { inputTypes } from '../../../constants/strings';
import MultiSelect from '../../forms/MultiSelect';
import { dateOptions } from '../../../utilities/inspection';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys.js';
import moment from 'moment';
import { useIsMobile } from '../../../hooks/misc.js';
import ImageWrapper from '../../offline-wrapper-functions/image-wrapper.js';
import { useObservations } from '../../../hooks/offline-hooks/observationHook.js';
import { useOnlineStatus } from '../../../hooks/offline-hooks/offline-misc.js';
import { useCurrentUser } from '../../../providers/UserProvider.jsx';
import QuickFileDropzone from '../../image_uploads/QuickFileDropzone.jsx';
import findFileIcon, { imageExtensions } from '../../../utilities/files.js';
import { useWorkspacePermissions } from '../../../providers/WorkspacePermissionsProvider.jsx';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { DropdownText } from '../../dropdowns/DropdownText.jsx';
import { useWorkspace } from '../../../providers/WorkspaceProvider.jsx';

const updateIncidentMutation = loader('./ObservationModal.incident.graphql');

export default function ObservationModal() {
  const { closeModal, updateModal, modalState, openModal } = useModal();
  const { user } = useCurrentUser();
  const { isWorkspaceAdmin } = useWorkspacePermissions();
  const { workspace } = useWorkspace();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const onHide = () => {
    closeModal({ modalName: modals.observation });
    setIsIncident(false);
  };
  const isMobile = useIsMobile();
  const online = useOnlineStatus();
  const { addObservations } = useObservations();
  const [isIncident, setIsIncident] = useState(false);
  const [updateIncident] = useMutation(updateIncidentMutation);

  const update = (variables) =>
    updateModal({
      modalName: modals.observation,
      variables: { ...variables },
    });

  const {
    show,
    dateTime,
    location,
    participants,
    negative,
    description,
    anonymous,
    images,
    refetch,
    questions,
    preview,
    incidentId,
  } = modalState.observationModal;
  const requiredQuestionIds =
    questions
      ?.filter((question) => question.required)
      .map((question) => question.id) || [];

  const allRequiredQuestionsAnswered = requiredQuestionIds.every((id) => {
    const answer = questions?.find((answer) => answer?.id === id);
    return answer && Boolean(answer.value);
  });

  const submitDisabled =
    !description || !dateTime || !allRequiredQuestionsAnswered || !location;

  const handleInputChange = (id, value, title, description) => {
    const updatedQuestions = questions?.map((q) =>
      q.id === id ? { ...q, value, title, description } : q,
    );
    update({
      questions: updatedQuestions,
    });
  };

  return (
    <Modal
      title={
        preview
          ? t(keys.observations.OBSERVATION_PREVIEW)
          : t(keys.observations.MAKE_AN_OBSERVATION)
      }
      open={show}
      hideSubmit={preview}
      onClose={onHide}
      hideCancel
      submitDisabled={submitDisabled}
      footer={
        preview ? null : (
          <div className={styles.check} data-cy="observation-anonymous-footer">
            <Text
              noMargin
              weight="semiBold"
              size="sm"
              color={isIncident ? 'secondaryLight' : 'primary'}
            >
              {t(keys.observations.ANONYMOUS_SUBMISSION)}
            </Text>
            <Form.Check
              disabled={isIncident}
              type="switch"
              onChange={(e) => update({ anonymous: e.target.checked })}
              checked={anonymous}
              data-cy="observation-anonymous-switch"
            />
          </div>
        )
      }
      onSubmit={() => {
        const formatQuestions = questions.map((q) => {
          if (!q.value) {
            q.value = t(keys.assessments.NO_ANSWER_PROVIDED);
          }
          return q;
        });

        const items = JSON.stringify(formatQuestions);

        addObservations({
          variables: {
            ...modalState.observationModal,
            participants: participants.map((u) => u.id),
            items,
          },
        })
          .then((response) => {
            if (!response || !response.data) {
              throw new Error('Invalid response structure');
            }

            const { data, errors } = response;

            if (errors) {
              showToast({
                title: 'Error',
                message: errors[0].message,
                variant: toastVariant.danger,
                time: toastLength.md,
              });
              return;
            }
            const { id } = data.addObservation || {};
            if (id) {
              showToast({
                title: t(keys.observations.OBSERVATION_SUBMITTED),
                message: t(keys.observations.OBSERVATION_SUBMITTED_MESSAGE),
                variant: toastVariant.info,
                time: toastLength.md,
              });
              if (online) {
                refetch();
              }
            }
            if (incidentId) {
              updateIncident({ variables: { incidentId, observationId: id } });
            }
            if (isIncident) {
              openModal({
                modalName: modals.createIncident,
                variables: {
                  title: `Observation ${id}`,
                  participants: participants
                    .map(({ id }) => id)
                    .includes(user.id)
                    ? [...participants]
                    : [...participants, user],
                  observation: data.addObservation,
                  type: 'Health and Safety',
                  subtype: 'Near Miss',
                  description,
                },
              });
            } else {
              showToast({
                title: t(keys.observations.OBSERVATION_SUBMITTED),
                message: t(keys.observations.OBSERVATION_SUBMITTED_MESSAGE),
                variant: toastVariant.info,
                time: toastLength.md,
              });
              refetch();
            }
          })
          .catch((error) => {
            showToast({
              title: t(keys.observations.SUBMIT_ERROR),
              message: t(keys.observations.SUBMIT_ERROR_MESSAGE, {
                variable: error.message || error,
              }),
              variant: toastVariant.danger,
              time: toastLength.md,
            });
          });
      }}
    >
      <div className={styles.container}>
        <div className={styles.topButtons}>
          <Button
            value={t(keys.observations.POSITIVE)}
            icon="mood"
            disabled={preview}
            size={isMobile ? 'sm' : 'md'}
            className={styles.button}
            outlined={negative}
            variant={negative ? 'secondary' : 'success'}
            onClick={() => update({ negative: false, type: null })}
            testId="observation"
          />
          <Button
            value={t(keys.observations.ACTION_REQUIRED)}
            icon="flag"
            disabled={preview}
            size={isMobile ? 'sm' : 'md'}
            variant={!negative ? 'secondary' : 'danger'}
            className={styles.button}
            outlined={!negative}
            onClick={() => update({ negative: true, type: null })}
            testId="observation"
          />
        </div>
        <Text noMargin weight="semiBold" size="sm">
          {t(keys.observations.SELECT_DATE_AND_TIME)}
          <span className={styles.red}>*</span>
        </Text>
        <input
          type="datetime-local"
          onChange={(e) => {
            const dateTime = e.target.value;
            update({ dateTime });
          }}
          className={styles.dateSelector}
          value={dateTime}
          data-cy="observation-date-time"
        />
        <Text noMargin weight="semiBold" size="sm">
          {t(keys.action.ENTER, { variable: t(keys.common.LOCATION) })}
          <span className={styles.red}>*</span>
        </Text>
        {!!workspace?.locations ? (
          <DropdownText
            placeholder={'Location'}
            selected={location}
            items={workspace.locations.split('|')}
            onChange={(location) => update({ location })}
            onRemove={() => update({ location: null })}
          />
        ) : (
          <Form.Control
            value={location || undefined}
            onChange={(e) => update({ location: e.target.value })}
            data-cy="observation-location"
          />
        )}
        <Text noMargin weight="semiBold" size="sm">
          {t(keys.action.ENTER, {
            variable: t(keys.observations.BRIEF_DESCRIPTION),
          })}
          <span className={styles.red}>*</span>
        </Text>
        <Form.Control
          as="textarea"
          onChange={(e) => update({ description: e.target.value })}
          value={description}
          data-cy="observation-description"
        />
        {questions?.map(
          ({ title, description, inputType, required, additionalData, id }) => {
            const dateFormat = dateOptions[inputType];
            const input = () => {
              switch (inputType) {
                case inputTypes.textMulti:
                  return (
                    <Form.Control
                      key={id}
                      as={'textarea'}
                      onChange={(e) =>
                        handleInputChange(
                          id,
                          e.target.value,
                          title,
                          description,
                        )
                      }
                      data-cy={`observation-question-${id}`}
                    />
                  );
                case inputTypes.select:
                  return (
                    <div data-cy={`observation-question-${id}`}>
                      {additionalData?.split('|').map((a) => (
                        <Form.Check
                          key={a}
                          type="radio"
                          label={a}
                          inline
                          name={`singleselect-${id}`}
                          value={a}
                          onChange={(e) => {
                            handleInputChange(
                              id,
                              e.target.value,
                              title,
                              description,
                            );
                          }}
                        />
                      ))}
                    </div>
                  );
                case inputTypes.multiSelect:
                  const values = questions?.find((q) => q.id === id)?.value;
                  return (
                    <MultiSelect
                      key={id}
                      title={' '}
                      inline
                      name={`multiselect-${id}`}
                      values={values || []}
                      options={additionalData?.split('|') || []}
                      onChange={(newValue) =>
                        handleInputChange(id, newValue, title, description)
                      }
                      data-cy={`observation-question-${id}`}
                    />
                  );
                case inputTypes.time:
                case inputTypes.date:
                case inputTypes.dateTime:
                  const type = () => {
                    switch (inputType) {
                      case inputTypes.time:
                        return 'time';
                      case inputTypes.dateTime:
                        return 'datetime-local';
                      default:
                        return 'date';
                    }
                  };
                  return (
                    <div key={id} data-cy={`observation-question-${id}`}>
                      <input
                        label={`${t(keys.action.SELECT_VARIABLE, {
                          variable: t(keys.common.DATE),
                        })} ${
                          inputType === inputTypes.date
                            ? t(keys.templates.TIME)
                            : ''
                        }`}
                        type={type()}
                        placeholder="Select Date"
                        className={styles.dateSelector}
                        id={`datetime-${id}`}
                        name={`datetime-${id}`}
                        onChange={(e) => {
                          let value = e.target.value;
                          if (inputType !== inputTypes.time)
                            value = moment(e.target.value).format(
                              dateFormat.format,
                            );
                          handleInputChange(id, value, title, description);
                        }}
                        data-cy={`observation-question-${id}`}
                      ></input>
                    </div>
                  );
                default:
                  return (
                    <Form.Control
                      key={id}
                      onChange={(e) =>
                        handleInputChange(
                          id,
                          e.target.value,
                          title,
                          description,
                        )
                      }
                      data-cy={`observation-question-${id}`}
                    />
                  );
              }
            };
            return (
              <div key={id} className={styles.templateQuestions}>
                <div>
                  <Text noMargin weight="semiBold" size="sm">
                    {title} {required ? '*' : ''}
                  </Text>
                  <Text noMargin size="sm" color="secondary">
                    {description}
                  </Text>
                </div>
                {input()}
              </div>
            );
          },
        )}
        <Text noMargin weight="semiBold" size="sm">
          {t(keys.observations.SELECT_TEAM)}
        </Text>
        <div className={styles.team}>
          <UserMultiSelector
            onUserAdded={(user) => {
              update({ participants: [...participants, user] });
            }}
            className={styles.team}
            selected={participants}
            onUserRemoved={(user) => {
              update({
                participants: participants?.filter((u) => u.id !== user.id),
              });
            }}
            data-cy="observation-participants"
          />
        </div>
        {loading ? (
          <Spinner
            className={styles.spinner}
            animation="border"
            variant="primary"
            data-cy="observation-loading-spinner"
          />
        ) : (
          <>
            <QuickFileDropzone
              onSubmit={({ url }) => {
                update({ images: [...images, url] });
              }}
              setLoading={setLoading}
              icon={!!images.length}
              loading={loading}
              iconText={t(keys.action.ADD, { variable: t(keys.common.FILE) })}
              data-cy="observation-file-dropzone"
            />
            <div className={styles.images}>
              {images.map((image, index) => {
                const extension = image.split('.').pop().toLowerCase();
                const isImage = imageExtensions.includes(extension);
                const src = isImage ? image : findFileIcon(extension);
                const file = {
                  fileType: extension,
                  url: image,
                  imageUrl: image,
                  downloadAllowed: true,
                };
                return (
                  <div
                    key={`image-${image}-${index}`}
                    data-cy={`observation-image-${index}`}
                  >
                    <ImageWrapper
                      key={image.id}
                      alt={`observation-${index}`}
                      style={{ height: '8rem', width: 'auto' }}
                      image={src}
                      className={isImage ? styles.image : styles.file}
                      onClick={() => openFileViewer(file)}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
        {negative && isWorkspaceAdmin ? (
          <Form.Group data-cy="observation-recordable-incident">
            <Form.Label as="legend">
              <Text noMargin weight="semiBold" size="sm">
                {t(keys.observations.RECORDABLE_INCIDENT)}
              </Text>
            </Form.Label>
            <Form.Check
              inline
              type="radio"
              label={t(keys.common.YES)}
              name="incidentOptions"
              onChange={() => {
                update({ anonymous: false });
                setIsIncident(true);
              }}
              checked={isIncident}
              data-cy="observation-incident-yes"
            />
            <Form.Check
              inline
              type="radio"
              label={t(keys.common.NO)}
              name="incidentOptions"
              onChange={() => setIsIncident(false)}
              checked={!isIncident}
              data-cy="observation-incident-no"
            />
          </Form.Group>
        ) : null}
      </div>
    </Modal>
  );
}
