import { StyleSheet } from '@react-pdf/renderer';
import { customColors } from '../misc';

const fontSize = 8;
const red = '#e9222c';
const textPrimary = '#1d1d1f';
const blue = '#0d6efd';
const gray = customColors.DARK_GRAY;
const yellow = customColors.YELLOW;
const green = customColors.GREEN;
const secondary = customColors.SECONDARY;
const secondaryLight = customColors.SECONDARY_LIGHT;

export const commentStyles = StyleSheet.create({
  comment: {
    display: 'flex',
    flexDirection: 'row',
    gap: 3,
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  commentContainer: { marginTop: 2, marginBottom: 2 },
  semiBold: {
    width: 120,
    fontSize,
    fontFamily: 'eina03semibold',
  },
  text: {
    fontSize,
    flexWrap: 'wrap',
    wordBreak: 'break-word',
    color: textPrimary,
    fontFamily: 'eina03regular',
  },
  red: {
    fontSize: fontSize + 1,
    color: red,
    marginBottom: 2,
  },
  commentLink: {
    color: blue,
    fontFamily: 'eina03semibold',
    fontSize,
    wordBreak: 'break-all',
    flexWrap: 'wrap',
  },
});

export const textStyles = StyleSheet.create({
  header: {
    fontSize: fontSize + 5,
    fontFamily: 'eina03bold',
    color: blue,
    marginBottom: 3,
    marginTop: 12,
  },
  blackHeader: {
    fontSize: fontSize + 5,
    fontFamily: 'eina03bold',
  },
  flexRow: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  },
  answer: {
    fontSize: fontSize + 1,
    fontFamily: 'eina03regular',
  },
  question: {
    fontSize: fontSize + 1,
    fontFamily: 'eina03semibold',
  },
  questionLg: {
    fontFamily: 'eina03bold',
    fontSize: fontSize + 2,
  },
  questionSm: {
    fontFamily: 'eina03semibold',
    fontSize: fontSize - 1,
  },
  subtext: {
    fontSize: fontSize + 1,
    fontFamily: 'eina03semibold',
    color: secondary,
  },
  content: {
    flexDirection: 'column',
    gap: 10,
    padding: 10,
  },
  red: {
    fontSize: fontSize + 2,
    color: red,
    fontFamily: 'eina03semibold',
    textAlign: 'right',
    marginLeft: 'auto',
  },
  yellow: {
    fontSize: fontSize + 2,
    fontFamily: 'eina03semibold',
    textAlign: 'right',
    marginLeft: 'auto',
    color: yellow,
  },
  green: {
    fontSize: fontSize + 2,
    fontFamily: 'eina03semibold',
    textAlign: 'right',
    marginLeft: 'auto',
    color: green,
  },
  gray: {
    fontSize: fontSize + 2,
    fontFamily: 'eina03semibold',
    textAlign: 'right',
    marginLeft: 'auto',
    color: gray,
  },
  categoryHeader: {
    fontSize: fontSize + 5,
    fontFamily: 'eina03bold',
    color: blue,
    marginBottom: 5,
    marginTop: 12,
  },
  largeTitle: {
    fontSize: fontSize + 5,
    fontFamily: 'eina03bold',
    marginBottom: 5,
    marginTop: 10,
  },
  spacer: {
    height: 10,
  },
  spacerSmall: {
    height: 7,
  },
  bottomBorder: {
    borderBottom: 1,
    borderBottomColor: secondaryLight,
    padding: 3,
  },
  row: {
    flexDirection: 'row',
    borderBottom: 1,
    borderBottomColor: secondaryLight,
    padding: 3,
    justifyContent: 'space-between',
  },
  leftText: {
    fontSize: fontSize + 1,
    fontFamily: 'eina03semibold',
    maxWidth: 300,
  },
  rightText: {
    marginLeft: 'auto',
    fontSize,
    fontFamily: 'eina03semibold',
    textAlign: 'right',
    alignSelf: 'right',
  },
  rightTextSmall: {
    marginLeft: 'auto',
    fontSize: fontSize - 1,
    fontFamily: 'eina03semibold',
    textAlign: 'right',
    color: secondary,
  },
  commentContainer: {
    padding: 5,
  },
  right: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: 3,
    textAlign: 'right',
  },
  textSmall: {
    color: secondary,
    fontSize: fontSize - 1,
    paddingBottom: 2,
  },
  label: {
    borderRadius: '50%',
    padding: 2,
    flexDirection: 'row',
    width: '60px',
    justifyContent: 'center',
    fontSize: fontSize,
    marginBottom: 3,
  },
  rowNoBorder: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  rightTextRegular: {
    marginLeft: 'auto',
    fontSize,
    fontFamily: 'eina03regular',
    textAlign: 'right',
    maxWidth: 100,
  },
  redLeft: {
    fontSize: fontSize,
    color: red,
    fontFamily: 'eina03semibold',
  },
  yellowLeft: {
    fontSize: fontSize - 1,
    fontFamily: 'eina03semibold',
    color: yellow,
  },
  greenLeft: {
    fontSize: fontSize,
    fontFamily: 'eina03semibold',
    color: green,
  },
  grayLeft: {
    fontSize: fontSize,
    fontFamily: 'eina03semibold',
    color: gray,
  },
});
