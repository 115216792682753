import styles from './CustomQuickActionModal.module.scss';
import Modal from '../Modal';
import { useState, useMemo, useEffect } from 'react';
import { Text } from '../../typography';
import Icon from '../../Icon';
import { Form } from 'react-bootstrap';
import TypeSelectorDropdown from '../../dropdowns/TypeSelectorDropdown';
import { assessmentTypes, quickActionTypes } from '../../../constants/strings';
import DropdownCombo from '../../dropdowns/DropdownCombo';
import { dropdownTypes } from '../../dropdowns/dropdown';
import { useWorkspace } from '../../../providers/WorkspaceProvider';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { iconNames } from '../../../utilities';
import { useMutation } from '@apollo/client';
import { useModal } from '../../../providers/ModalProvider';
import { modals } from '../../../providers/modals';
import { DropdownText } from '../../dropdowns/DropdownText';
import { useTemplates } from '../../../providers/TemplateProvider';
import classNames from 'classnames';
import { useCurrentUser } from '../../../providers/UserProvider';
import Button from '../../Button';
import QuickFileDropzone from '../../image_uploads/QuickFileDropzone';
import { useQueryParams } from '../../../hooks/misc';
import Select from 'react-select';

const modalQuery = loader('./CustomQuickActionModal.fetch.graphql');
const addQuickActionMutation = loader('./CustomQuickActionModal.add.graphql');
const interalFilesQuery = loader('./CustomQuickActionModal.files.graphql');

export default function CustomQuickActionModal() {
  const { t } = useTranslation();
  const [addQuickAction] = useMutation(addQuickActionMutation);
  const { modalState, closeModal, updateModal } = useModal();
  const { assessmentName } = useCurrentUser();
  const { inWorkspaces, availableWorkspaces } = useWorkspace();
  const {
    color,
    name,
    icon,
    type,
    template,
    asset,
    workspace,
    quickActionType,
    url,
    show,
    refetch,
  } = modalState.quickActionModal;

  const { data: { files = [] } = {} } = useQuery(interalFilesQuery, {
    skip: !workspace?.id || !show,
    variables: {
      options: {
        filters: [
          {
            field: 'workspaceId',
            operator: 'or',
            value: [`${workspace?.id}`, null],
          },
        ],
      },
    },
  });
  const fileOptions = useMemo(() => {
    return files?.map((f) => ({ value: f.id, label: f.name, ...f }));
  }, [files]);

  const { allTemplates } = useTemplates();
  const templates = useMemo(() => {
    return allTemplates.filter(
      (tem) =>
        (tem.workspaceId === workspace?.id || tem.workspaceId === null) &&
        !tem?.isArchived &&
        tem?.templateType === type?.key,
    );
  }, [allTemplates, workspace?.id, type]);

  const [selectedType, setSelectedType] = useState('ASSESSMENT');
  const [page, setPage] = useState(1);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getParam } = useQueryParams();
  const [allWorkspaces, setAllWorkspaces] = useState(false);

  const onHide = () => {
    setSelectedType('ASSESSMENT');
    setPage(1);
    setSelectedFile(null);
    setLoading(false);
    closeModal({ modalName: modals.quickAction });
  };

  const update = (variables) =>
    updateModal({
      modalName: modals.quickAction,
      variables: { ...variables },
    });

  const typeOptions = Object.values(assessmentTypes)
    .map((t, index) => ({
      title: t.title,
      icon: t.icon,
      color: t.color,
      key: t.key,
      id: index,
    }))
    .filter((t) => t.key !== 'OBSERVATION' && t.key !== 'INCIDENT');

  const editedQuickAction = useMemo(() => {
    let newUrl = url;
    if (selectedType === 'ASSESSMENT') {
      newUrl = `/workspace/${workspace?.id}/dashboard?&modalName=createAssessmentModal&type=${type?.key}`;
      if (asset?.id) {
        newUrl = newUrl.concat(`&asset=${asset.id}`);
      }
      if (template?.id) {
        newUrl = newUrl.concat(`&template=${template.id}`);
      }
    } else if (
      selectedType === 'LINK' &&
      !!url &&
      !url?.startsWith('http://') &&
      !url?.startsWith('https://')
    ) {
      newUrl = `https://${url}`;
    }

    return {
      color,
      name,
      icon,
      url: newUrl || selectedFile?.url,
      workspaceId: workspace?.id,
      template: template?.id,
      type: quickActionType,
    };
  }, [
    url,
    selectedType,
    color,
    name,
    icon,
    selectedFile?.url,
    workspace?.id,
    template?.id,
    quickActionType,
    type?.key,
    asset?.id,
  ]);

  const tab = getParam('tab');

  const optional = type?.key !== assessmentTypes.fleet.key;

  const { data: { workspaceVehicles = [] } = {} } = useQuery(modalQuery, {
    skip: !show || !workspace?.id,
    variables: {
      workspaceId: `${workspace?.id}`,
    },
  });

  const buttons = [
    {
      title: assessmentName || t(keys.common.ASSESSMENT),
      icon: 'inventory',
      key: 'ASSESSMENT',
    },
    {
      title: t(keys.common.LINK),
      icon: 'link',
      key: 'LINK',
    },
    {
      title: 'Upload Document',
      icon: 'folder',
      key: 'DOC',
    },
    {
      title: 'Internal Document',
      icon: 'folder',
      key: 'INTERNAL',
    },
  ];
  useEffect(() => {
    setSelectedFile(null);
  }, [selectedType]);

  const submitDisabled = useMemo(() => {
    if (page === 2) {
      switch (selectedType) {
        case 'DOC':
        case 'INTERNAL':
          return !selectedFile;
        case 'LINK':
          return !url;
        case 'ASSESSMENT':
          return !template;
        default:
          return false;
      }
    }
  }, [page, selectedFile, selectedType, template, url]);

  return (
    <Modal
      open={show}
      disableCloseOnSubmit={page === 1}
      disableCloseOnCancel={page === 2}
      onClose={() => {
        onHide();
        setPage(1);
      }}
      submitDisabled={submitDisabled}
      footer={
        !!tab &&
        tab === 'workspaces' &&
        page === 2 &&
        selectedType !== 'ASSESSMENT' && (
          <div className={styles.flex}>
            <Text noMargin weight="semiBold">
              Apply to all workspaces
            </Text>
            <Form.Check
              onClick={(e) => setAllWorkspaces(e.target.checked)}
              type="switch"
            />
          </div>
        )
      }
      title={t(keys.dashboard.CUSTOM_QUICK_ACTION)}
      submitText={page === 1 && t(keys.action.NEXT)}
      cancelText={page === 2 && t(keys.action.BACK)}
      onSubmit={async () => {
        if (page === 1) {
          setPage(2);
        } else {
          if (!!allWorkspaces) {
            for (const workspace of availableWorkspaces) {
              await addQuickAction({
                variables: { ...editedQuickAction, workspaceId: workspace.id },
              });
            }
          } else {
            await addQuickAction({ variables: { ...editedQuickAction } });
          }
          refetch();
        }
      }}
      onCancel={() => {
        if (page === 2) {
          setPage(1);
        }
      }}
    >
      <div>
        {page === 1 ? (
          <div className={styles.container}>
            <div className={styles.top}>
              <div>
                <Text weight="semiBold">{t(keys.dashboard.ICON)}</Text>
                <DropdownText
                  items={iconNames}
                  type={dropdownTypes.ICONS}
                  selected={icon}
                  onChange={(icon) => {
                    update({
                      icon,
                    });
                  }}
                />
              </div>
              <div style={{ width: '100%' }}>
                <Text weight="semiBold">{t(keys.common.NAME)}</Text>
                <Form.Control
                  placeholder={t(keys.action.ENTER, {
                    variable: t(keys.common.NAME),
                  })}
                  maxLength={30}
                  value={name || ''}
                  onChange={(e) => update({ name: e.target.value })}
                />
              </div>
            </div>
            <div className={styles.wrapper}>
              <div
                className={styles.buttonPreview}
                style={{ backgroundColor: color }}
              >
                <input
                  className={styles.colorPicker}
                  type="color"
                  value={color || ''}
                  onChange={(e) => update({ color: e.target.value })}
                />
                {!!icon && (
                  <Icon
                    baseClassName="material-icons-outlined"
                    fontSize="inherit"
                    color="white"
                  >
                    {`${icon}`}
                  </Icon>
                )}
                <Text
                  size={!!icon ? 'sm' : 'md'}
                  weight="semiBold"
                  textAlign="center"
                  color="white"
                  noMargin
                  className={styles.text}
                >
                  {name}
                </Text>
              </div>
            </div>
            <Text size="sm" color="secondary" textAlign="center">
              {t(keys.dashboard.CLICK_TO_CHANGE_COLOR)}
            </Text>
          </div>
        ) : (
          <div className={styles.page2}>
            <div className={styles.quickActionSelectionContainer}>
              {buttons.map(({ title, icon, key }) => (
                <div
                  className={classNames(
                    styles.containerLink,
                    selectedType === key ? styles.borderColor : null,
                  )}
                  onClick={() => {
                    setSelectedType(key);
                  }}
                >
                  <Icon
                    baseClassName="material-icons-outlined"
                    className={styles.icon}
                    fontSize="inherit"
                  >
                    {icon}
                  </Icon>
                  <Text size="md" weight="semiBold" textAlign="center" noMargin>
                    {title}
                  </Text>
                </div>
              ))}
            </div>
            <br />
            {selectedType === 'ASSESSMENT' && (
              <div className={styles.dropdownsContainer}>
                <TypeSelectorDropdown
                  onChange={(type) =>
                    update({
                      type,
                      template: null,
                      asset: null,
                    })
                  }
                  selected={type || assessmentTypes.fleet}
                  items={typeOptions}
                  type="Type"
                />
                {quickActionType === quickActionTypes.userLevel && (
                  <DropdownCombo
                    title={t(keys.common.WORKSPACE)}
                    preventStateChange
                    required
                    type={dropdownTypes.WORKSPACE}
                    items={inWorkspaces || []}
                    onChange={(workspace) =>
                      update({ workspace, asset: null, template: null })
                    }
                    selected={workspace}
                  />
                )}
                <DropdownCombo
                  type={dropdownTypes.ASSET}
                  title={t(keys.common.ASSET)}
                  items={workspaceVehicles || []}
                  onChange={(asset) => {
                    update({
                      asset,
                    });
                  }}
                  selected={asset}
                  optional={optional}
                  onRemove={() => update({ asset: null })}
                  required={type?.key === 'VEHICLE'}
                  highlight={type?.key === 'VEHICLE'}
                />
                <DropdownCombo
                  type={dropdownTypes.TEMPLATE}
                  title={t(keys.common.TEMPLATE)}
                  highlight
                  preventStateChange
                  items={templates || []}
                  onChange={(template) => {
                    update({
                      template,
                    });
                  }}
                  onRemove={() => update({ template: null })}
                  selected={template}
                />
                <br />
              </div>
            )}
            {selectedType === 'LINK' && (
              <>
                <div className={styles.flex}>
                  <Form.Control
                    placeholder="Enter URL"
                    value={url || ''}
                    onChange={(e) => {
                      update({ url: e.target.value });
                    }}
                    className={styles.urlInput}
                  />
                  <Button
                    value="Test"
                    onClick={() =>
                      window.open(
                        editedQuickAction.url,
                        '_blank',
                        'noopener,noreferrer',
                      )
                    }
                  />
                </div>
              </>
            )}

            {selectedFile ? (
              <div className={styles.fileInput}>
                <Text
                  hover
                  color="accentPrimary"
                  noMargin
                  textAlign="center"
                  weight="semiBold"
                  onClick={() => window.open(selectedFile.url)}
                >
                  {selectedFile?.name}
                </Text>
                <Icon hover color="red" onClick={() => setSelectedFile(null)}>
                  delete
                </Icon>
              </div>
            ) : selectedType === 'DOC' ? (
              <QuickFileDropzone
                loading={loading}
                setLoading={setLoading}
                onSubmit={(file) => {
                  setSelectedFile(file);
                  update({ url: file.url });
                }}
              />
            ) : (
              selectedType === 'INTERNAL' && (
                <div>
                  <Text noMargin weight="semiBold">
                    Select Internal File
                  </Text>
                  <div className={styles.spacer} />
                  <Select
                    options={fileOptions}
                    onChange={(file) => {
                      setSelectedFile(file);
                    }}
                  />
                  <br />
                  <br />
                </div>
              )
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}
