import { useMemo, useState } from 'react';
import styles from './MTableComponents.module.scss';
import {
  TextField,
  Box,
  Icon,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';
import moment from 'moment';
import ClearIcon from '@mui/icons-material/Clear';
import { Text } from '../typography';
import SimpleUserCard from '../SimpleUserCard';
import { getLocalTime } from '../../utilities/time';
import Label from '../action_items/Label';
import defaultLogo from '../../assets/workspace_image.png';
import { vehicleString } from '../../utilities';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useWorkspacePermissions } from '../../providers/WorkspacePermissionsProvider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useQueryParams } from '../../hooks/misc';
import { useLocation } from 'react-router-dom';
import { tableSortingParams } from './table';
import { Form } from 'react-bootstrap';
import noImage from '../../assets/icons/image_blank.png';
import { useCurrentUser } from '../../providers/UserProvider';

const dateFormatWithTime = 'dddd MMM Do YYYY - h:mma'; // hh is 12hr clock, a shows am/pm at the end
const dateFormatWithoutTime = 'dddd MMM Do YYYY';
const maxLabels = 3;

export const CustomDateRangeFilter = ({
  column,
  minDate,
  maxDate,
  setMinDate,
  setMaxDate,
}) => {
  const handleMinDateChange = (event) => {
    const value = event.target.value;
    const formattedValue = moment(value).format('YYYY-MM-DD');
    setMinDate(formattedValue);
    column.setFilterValue([formattedValue, maxDate]);
  };

  const handleMaxDateChange = (event) => {
    const value = event.target.value;
    const formattedValue = moment(value).format('YYYY-MM-DD');
    setMaxDate(formattedValue);
    column.setFilterValue([minDate, formattedValue]);
  };
  const clearMinDate = () => {
    setMinDate('');
    column.setFilterValue(['', maxDate]);
  };

  const clearMaxDate = () => {
    setMaxDate('');
    column.setFilterValue([minDate, '']);
  };

  return (
    <Box display="flex" maxWidth={'20rem'} borderBottom={'1px solid gray'}>
      <TextField
        type="date"
        value={minDate || ''}
        onChange={handleMinDateChange}
        label="Min"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          className: !!minDate ? styles.dateSelector : styles.placeholder,
          style: { fontSize: 'small' },
          placeholder: 'Min',
          endAdornment: !!minDate ? (
            <ClearIcon
              className={styles.clear}
              fontSize="1rem"
              onClick={clearMinDate}
            />
          ) : null,
        }}
      />
      <TextField
        type="date"
        value={maxDate || ''}
        label="Max"
        onChange={handleMaxDateChange}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          className: !!maxDate ? styles.dateSelector : styles.placeholder,
          style: { fontSize: 'small' },
          endAdornment: !!maxDate ? (
            <ClearIcon
              className={styles.clear}
              fontSize="1rem"
              onClick={clearMaxDate}
            />
          ) : null,
        }}
      />
    </Box>
  );
};

export const formatFilters = (originalField, originalValue) => {
  let operator = 'eq';
  let value = originalValue;
  let field = originalField;
  switch (originalField) {
    case 'description':
    case 'title':
      operator = 'iLike';
      value = `%${value}%`;
      break;
    case 'reviewer':
    case 'creator':
    case 'workspace':
      field = `${field}Id`;
      break;
    case 'dateTime':
    case 'dateCreated':
      const [min, max] = originalValue;
      const dateMin =
        min && moment(min).isValid()
          ? moment(min).format('YYYY-MM-DD 00:00:00')
          : null;
      const dateMax =
        max && moment(max).isValid()
          ? moment(max).format('YYYY-MM-DD 23:59:59')
          : null;
      if (dateMin && !dateMax) {
        operator = 'gte';
        value = [dateMin];
      } else if (!dateMin && dateMax) {
        operator = 'lte';
        value = [dateMax];
      } else if (dateMin && dateMax) {
        operator = 'between';
        value = [dateMin, dateMax];
      } else {
        return null;
      }
      break;
    default:
      break;
  }
  return { field, operator, value };
};

export const useTableComponents = () => {
  const { t } = useTranslation();
  const { allUsers, allWorkspaceUsers } = useWorkspacePermissions();
  const { user } = useCurrentUser();

  const assetFilterOptions = (data) => {
    return data.map((u) => ({
      label: vehicleString(u),
      value: `${u.id}`,
    }));
  };
  const assetCell = ({ cell }) =>
    !!cell.getValue() ? (
      <div className={styles.workspace}>
        <img
          className={styles.workspaceLogo}
          src={cell.getValue()?.imageUrl || noImage}
          alt="vehicle"
        />
        <Text noMargin size="sm" weight="semiBold" truncate>
          {vehicleString(cell.getValue())}
        </Text>
      </div>
    ) : (
      <Text noMargin color="secondary" size="sm">
        {t(keys.common.NONE)}
      </Text>
    );
  const textCell = ({ cell }, color = 'primary') =>
    cell.getValue() !== null && !!cell.getValue() ? (
      <Text noMargin weight="semiBold" size="sm" truncate color={color}>
        {cell.getValue()}
      </Text>
    ) : (
      <Text noMargin color="secondary" size="sm">
        {t(keys.common.NONE)}
      </Text>
    );

  const customTextCell = (value, color = 'primary') => (
    <Text noMargin weight="semiBold" size="sm" truncate color={color}>
      {value}
    </Text>
  );

  const externalIdCell = ({ id, externalId }) =>
    !id ? (
      <Text noMargin color="secondary" size="sm">
        {t(keys.common.NONE)}
      </Text>
    ) : (
      <Text noMargin weight="semiBold" size="sm">
        {externalId ||
          `${user.company?.incidentName || 'INC'}-${String(id).padStart(
            5,
            '0',
          )}`}
      </Text>
    );

  const userCell = ({ cell }) => (
    <SimpleUserCard size="sm" user={cell.getValue()} />
  );
  const dateCell = ({ cell }, { color = 'primary', dateOnly = false } = {}) => {
    return !!cell.getValue() ? (
      <Text noMargin weight="semiBold" size="sm" color={color}>
        {getLocalTime(cell.getValue()).format(
          dateOnly ? dateFormatWithoutTime : dateFormatWithTime,
        )}
      </Text>
    ) : (
      <Text size="sm" noMargin>
        {t(keys.common.NONE)}
      </Text>
    );
  };
  const dateCellFromNow = ({ cell }) => {
    return !!cell.getValue() ? (
      <Text noMargin weight="semiBold" size="sm">
        {getLocalTime(cell.getValue()).fromNow()}
      </Text>
    ) : (
      <Text size="sm" noMargin>
        {t(keys.common.NONE)}
      </Text>
    );
  };
  const labelCell = ({ name, color }) => {
    return <Label name={name || 'unknown'} color={color || 'grey'} />;
  };

  const multiLabelCell = ({ labels }) => {
    return (
      <div className={styles.labelContainer}>
        {labels?.slice(0, maxLabels).map((label) => (
          <Label
            key={label.id}
            className={styles.label}
            name={label.name}
            color={label.color.toLowerCase()}
          />
        ))}
        {labels?.length > maxLabels && (
          <Text
            size="sm"
            weight="semiBold"
            noMargin
            color="accentPrimary"
          >{`+ ${labels.length - maxLabels}`}</Text>
        )}
      </div>
    );
  };

  const userFilterOptions = (type = 'workspace') => {
    const users = type === 'all' ? allUsers : allWorkspaceUsers;
    return users?.map((u) => ({
      label: `${u.firstName} ${u.lastName}`,
      value: `${u.id}`,
    }));
  };

  const workspaceCell = ({ cell }) => {
    return (
      <div className={styles.workspace}>
        {cell.getValue()?.title ? (
          <>
            <img
              src={cell.getValue()?.logoUrl || defaultLogo}
              alt="workspace_img"
              className={styles.workspaceLogo}
            />
            <Text noMargin weight="semiBold" size="sm" truncate>
              {cell.getValue()?.title}
            </Text>
          </>
        ) : (
          <Text noMargin size="sm" color="secondary">
            {t(keys.common.NONE)}
          </Text>
        )}
      </div>
    );
  };

  const currentWorkspaceCell = ({ cell, original }) => {
    const workspaces = cell.getValue();
    const lastWorkspace =
      workspaces.find((w) => w.id === original.lastWorkspaceId) ??
      workspaces[0];
    return (
      <div className={styles.workspace}>
        {lastWorkspace.title ? (
          <>
            <img
              src={lastWorkspace.logoUrl || defaultLogo}
              alt="workspace_img"
              className={styles.workspaceLogo}
            />
            <Text noMargin weight="semiBold" size="sm" truncate>
              {lastWorkspace.title}
            </Text>
          </>
        ) : (
          <Text noMargin weight="semiBold" size="sm" truncate color="secondary">
            {t(keys.common.NONE)}
          </Text>
        )}
      </div>
    );
  };

  const dropdownCell = ({
    valueStrings,
    values,
    isValueInt = false,
    selected,
    onChange = () => {},
    rowId,
    disabled = false,
  }) => {
    const options = Object.keys(values).map((key) => (
      <option key={key} value={values[key]}>
        {valueStrings[key]}
      </option>
    ));

    return (
      <div
        className={styles.dropdownCell}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <Form.Select
          value={selected}
          disabled={disabled}
          onChange={(e) => {
            onChange(
              rowId,
              isValueInt ? parseInt(e.target.value) : e.target.value,
            );
          }}
        >
          {options}
        </Form.Select>
      </div>
    );
  };

  const multiTextCell = ({ cell }, color = 'primary') => {
    const roles = cell.getValue();

    const roleTitles =
      roles?.length > 0
        ? roles.map(({ title }) => title).join(', ')
        : t(keys.common.NONE);

    return (
      <Text noMargin weight="semiBold" size="sm" truncate color={color}>
        {roleTitles}
      </Text>
    );
  };

  return {
    textCell,
    customTextCell,
    userCell,
    dateCell,
    dateCellFromNow,
    userFilterOptions,
    labelCell,
    multiLabelCell,
    workspaceCell,
    currentWorkspaceCell,
    dropdownCell,
    assetCell,
    assetFilterOptions,
    externalIdCell,
    multiTextCell,
  };
};

export const tableStyle = {
  muiTableHeadCellProps: () => ({
    style: {
      fontSize: '12px',
      border: 'none',
    },
  }),
  muiTablePaperProps: () => ({
    style: {
      border: 'none',
      padding: '1rem',
      boxShadow: 'none',
      width: '100%',
      borderRadius: '10px',
    },
  }),
};

export const tableOptions = {
  enableColumnOrdering: true,
  enableColumnResizing: true,
  enableSorting: true,
  manualPagination: true,
  enableGlobalFilter: false,
  manualFiltering: true,
  manualSorting: true,
  enableFilterMatchHighlighting: false,
  enableFacetedValues: true,
};
export const checkDueDateColor = ({ dueDate, status }) => {
  const today = new moment();
  if (status === 'DONE' || status === 'DUPLICATE') {
    return 'secondary';
  }
  if (status !== 'DONE' && dueDate) {
    if (moment(parseInt(dueDate)).isBefore(today, 'day')) {
      return 'red';
    }
    if (
      moment(parseInt(dueDate)).isSame(today, 'day') ||
      moment(parseInt(dueDate)).isSame(today.add(1, 'day'), 'day')
    ) {
      return 'yellow';
    }
  } else {
    return 'primary';
  }
};

export const LabelFilter = ({
  column,
  selectedLabels,
  labels,
  handleChange,
  handleRemove,
}) => {
  const filteredLabels = useMemo(() => {
    return labels.filter((l) => !selectedLabels?.some((m) => m.id === l.id));
  }, [labels, selectedLabels]);

  return (
    <Box
      display="flex"
      maxWidth={'20rem'}
      minHeight={'2rem'}
      height={'auto'}
      alignItems={'center'}
    >
      <FormControl variant="standard" sx={{ minWidth: 120, width: '100%' }}>
        <Select
          SelectDisplayProps={{}}
          value={selectedLabels}
          onChange={(e) => {
            handleChange(e.target.value);
            column.setFilterValue(e.target.value.map(({ id }) => id));
          }}
          multiple
          renderValue={(labels) => (
            <div className={styles.selectedLabels}>
              {labels.map((label) => (
                <Label
                  key={label.id}
                  name={label.name}
                  color={label.color}
                  className={styles.labelFilter}
                />
              ))}
            </div>
          )}
        >
          {filteredLabels.map((label) => (
            <MenuItem value={label} key={label.id}>
              <Label key={label.id} name={label.name} color={label.color} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedLabels?.length > 0 && (
        <Icon className={styles.close} onClick={() => handleRemove()}>
          close
        </Icon>
      )}
    </Box>
  );
};

const tableStatus = (tab, type) => {
  switch (type) {
    case 'assessments':
    case 'templates':
      return ['isDraft', tab === 1 ? true : false];
    case 'cpa':
      return ['open', tab === 1 ? true : false];
    default:
      return null;
  }
};

export const tableLabels = {
  templates: ['All', 'Draft', 'Published'],
  assessments: ['All', 'In Progress', 'Complete'],
  cpa: ['All', 'Open', 'Closed'],
};

export function TableTabs({ parent, onChange }) {
  const [value, setValue] = useState(0);
  const labels = tableLabels[parent];
  const { upsertParam, deleteParam } = useQueryParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const handleChange = async (event, newValue) => {
    setValue(newValue);

    const deletionPromises = [];
    for (const key of Array.from(searchParams.keys())) {
      if (!tableSortingParams.includes(key)) {
        deletionPromises.push(deleteParam(key));
      }
    }
    await Promise.all(deletionPromises);

    onChange();
    const filters = tableStatus(newValue, parent);
    if (newValue !== 0 && filters) {
      upsertParam(filters[0], filters[1]);
    }
  };

  return (
    <div className={styles.tabsContainer}>
      <Tabs value={value} onChange={handleChange}>
        {labels?.map((label, idx) => (
          <Tab key={label} label={label} value={idx} />
        ))}
      </Tabs>
    </div>
  );
}
