import React from 'react';
import classNames from 'classnames';
import styles from './RequirementsList.module.scss';
import {
  getExpiryColor,
  getExpiryStatus,
} from '../../utilities/complainceUtilities';

import { modals } from '../../providers/modals';
import { useTranslation } from 'react-i18next';
import { getLocalTime } from '../../utilities/time';
import { Text } from '../../components/typography';
import Icon from '../../components/Icon';
import { Button } from '../../components';
import { keys } from '../../utilities/translator/translation_keys';
import { useModal } from '../../providers/ModalProvider';
import { fileExpiryStatus } from '../../constants/misc';
import { useCurrentUser } from '../../providers/UserProvider';
import { userRoles } from '../../constants/strings';

export default function RequirementsList({
  user = {},
  role = {},
  expandedCertificateState,
  toggleCertificateExpand,
}) {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { user: currentUser } = useCurrentUser();
  const handleAddCertificate = (requirementId) => {
    openModal({
      modalName: modals.certificate,
      variables: {
        certificateId: null,
        requirementId,
        userId: user?.id,
      },
    });
  };

  const getCertificateText = (certifications = []) => {
    if (!certifications.length) {
      return `${t(keys.common.NO)} ${t(keys.common.CERTIFICATE)}`;
    }

    const expiresDate = certifications[0]?.expires
      ? getLocalTime(certifications[0].expires).format('MMMM Do YYYY')
      : 'Never';

    return `${t(keys.action.EXPIRES)} ${expiresDate}`;
  };

  return (
    <div className={styles.roleBox}>
      <Text weight="semiBold" noMargin>
        {t(keys.roles.REQUIREMENT)}s
      </Text>
      {!role?.requirements?.length < 1 ? (
        role?.requirements?.map((requirement) => (
          <div className={styles.requirementBox} key={requirement.id}>
            <div className={styles.requirementBoxTitle}>
              <div>
                <Text weight="semiBold" noMargin>
                  {requirement.title}
                </Text>
                <Text
                  noMargin
                  weight="bold"
                  size="sm"
                  className={
                    !requirement?.certifications?.length
                      ? getExpiryColor(fileExpiryStatus.expired)
                      : getExpiryColor(
                          getExpiryStatus(
                            requirement?.certifications[0]?.expires,
                          ),
                        )
                  }
                >
                  {getCertificateText(requirement?.certifications)}
                </Text>
              </div>
              <div>
                {currentUser.role === userRoles.admin && (
                  <Icon
                    onClick={() => handleAddCertificate(requirement.id)}
                    className={styles.addIcon}
                    baseClassName="material-icons-outlined"
                  >
                    add
                  </Icon>
                )}
                <Icon
                  disabled={!requirement?.certifications?.length}
                  onClick={() => toggleCertificateExpand(requirement.id)}
                  className={styles.editIcon}
                  baseClassName="material-icons-outlined"
                >
                  {expandedCertificateState[requirement.id]
                    ? 'keyboard_arrow_up'
                    : 'keyboard_arrow_down'}
                </Icon>
              </div>
            </div>
            <div
              className={classNames(
                styles.certificateContainer,
                expandedCertificateState[requirement.id]
                  ? styles.expanded
                  : styles.collapsed,
              )}
            >
              {requirement?.certifications?.map((certificate) => (
                <div key={certificate?.id} className={styles.certificateCard}>
                  <Text
                    noMargin
                    weight="bold"
                    size="sm"
                    className={getExpiryColor(
                      getExpiryStatus(certificate?.expires),
                    )}
                  >
                    {certificate?.expires
                      ? getLocalTime(certificate?.expires).format(
                          'MMMM Do YYYY',
                        )
                      : t(keys.action.EXPIRES) + ' Never'}
                  </Text>
                  <div>
                    <Button
                      outlined
                      variant="secondary"
                      size="sm"
                      value={t(keys.assessments.VIEW)}
                      onClick={() => {
                        openModal({
                          modalName: modals.certificate,
                          variables: {
                            certificateId: certificate.id,
                            certificateData: certificate,
                            files: certificate.files,
                            userId: user.id,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        <Text noMargin>None</Text>
      )}
    </div>
  );
}
